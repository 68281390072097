import React, { Component } from "react";
import { Icon, Col, Card, Tooltip, Row , Popover} from "antd";
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faMapMarked} from '@fortawesome/free-solid-svg-icons';
import { faClone } from '@fortawesome/free-regular-svg-icons'
import { Divider } from 'antd';
import NumberFormat from "./NumberFormat";
import { getWorkingHoursList } from "../services/display";
import {calculateHoursForImpressions} from "../services/utils"
import TagsDiscount from "./TagsDiscount"
import {getLocationType} from "../services/display"
class PrincipalInformationScreenComponent extends Component {
  constructor(props) {
    super(props);
  }

  getSizeType(type) {
    if (type === "small") {
      return this.props.t('Small');
    }
    if (type === "medium") {
      return this.props.t('Medium');
    }
    if (type === "big") {
      return this.props.t('Big');
    }
    if (type === "giant") {
      return this.props.t('Giant');
    }
  }
  getLocation(){
    const { display} = this.props;
    if(display.secondary_displays && display.secondary_displays.length>0){
      let msg = ""
      display.secondary_displays.map((secondary_display)=>{
        msg = msg + secondary_display.administrative_area_level_2 + ", "+
                    secondary_display.administrative_area_level_1 + ", "+
                    secondary_display.country + "\n"
      })
      return(
        <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} placement="topLeft" title={msg}>
          <Icon
          style={{ fontSize: "15px", marginRight: "5px"}}
          type="info-circle"/>
          <span className="primary">
            <h3 style={{display: "inline"}}>{this.props.t('Multiple screens')}</h3>
          </span>
        </Tooltip>)
    }
    let titeLocation = display.country
    titeLocation = display.administrative_area_level_1 ? display.administrative_area_level_1 + ", " + titeLocation:titeLocation
    titeLocation = display.administrative_area_level_2 ? display.administrative_area_level_2 + ", " + titeLocation:titeLocation
    let formattedAddress = display.formatted_address
    return (
      <>
        <Icon
        style={{ fontSize: "15px", marginRight: "5px"}}
        type="environment"/>
        <span className="primary">
          <h3 style={{display: "inline"}}>{titeLocation}</h3>
          <p style={{ fontSize: "12px", marginLeft: "20px"}}>{formattedAddress}</p>
        </span>
      </>
    )
  }
  render() {
    const { t , display} = this.props;
    var wednesdayMinutes = calculateHoursForImpressions(display)
    var workingHoursList = []
    const withForInfo = this.props.programmatic === 'programmatic' ? 8 : 12
    if (display.working_hours) {
     getWorkingHoursList(t, display.working_hours, workingHoursList)
    }

    return (
      <Card className="screenCard">
        <div className="screenCardContent span-description">
          <h2>
            {display.name}
            {display.use_cms === 0 ? (
              <Tooltip placement="bottomLeft" title={t('Non-automated screen') + '. ' + t('This screen does not have the real-time print notice. Therefore, print reports may take up to 48 hours to be reflected.')}>
                <Icon type="warning" theme="outlined" style={{color: "#ffc107", marginLeft: "20px"}}/>
              </Tooltip>
            ) : null}
          </h2>
          {this.getLocation()}
          <Divider className="line-divider"/>
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="screenItemContainer">
                <b>
                  <FontAwesomeIcon icon={faMapMarkedAlt} size="20x" className='icoinversenEdit' />{" "}
                  {t('Type')}
                  <br />
                  <span className="primary span-description">{`${getLocationType(
                    display.location_type, t
                  )}`}</span>
                </b>{" "}
              </div>
              {display.open_ooh_venue_type_id &&
                <div className="screenItemContainer">
                  <b>
                    <FontAwesomeIcon icon={faMapMarked} size="20x" className='iconTranslate' />
                    {t('Sub Type of screen')}
                    <br />
                    <span className="primary span-description">{t('subtype_'+ display.open_ooh_venue_type_id)}</span>
                  </b>{" "}
                </div>
              }
              <div className="screenItemContainer">
                <b>
                  <Icon
                    style={{ fontSize: "18px" }}
                    type="drag"
                  />{" "}
                  {t('Size')}
                  <br />
                  <span className="primary span-description">{`${this.getSizeType(
                    display.size_type
                  )}, ${display.size_width}m x ${display.size_height}m`}</span>
                </b>{" "}
              </div>
              <div className="screenItemContainer">
                <b>
                  <Icon
                    style={{ fontSize: "18px" }}
                    type="arrows-alt"
                  />{" "}
                  {t('Resolution')}
                  <br />
                  <span className="primary span-description">
                    {display.resolution_width + "x" + display.resolution_height}
                  </span>
                </b>{" "}
              </div>
              {display.mirror_screens_count > 1 ? (
                <div className="screenItemContainer">
                  <b>
                    <FontAwesomeIcon icon={faClone} size="20x" className='iconTranslate'/>
                    {" "}
                    {t('Multiple faces screen')}
                    <br/>
                    <span className="primary span-description">
                    {display.mirror_screens_count + " " + t('sides')}
                    </span>
                  </b>{" "}
                </div>
              ):null}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="screenItemContainer">
                <b>
                  <Icon
                    style={{ fontSize: "18px" }}
                    type="dashboard"
                  />{" "}
                  {t('')}
                  {t('Ads time')}
                  <br />
                  <span className="primary span-description">
                    {display.slot_length/1000 + ' seg'}
                      <Tooltip
                        placement="right"
                        title={
                          <span>{t('Length of ads time')}</span>
                        }>
                      <Icon
                        style={{ fontSize: "14px", marginLeft:"10px"}}
                        type="info-circle"
                      />
                    </Tooltip>
                  </span>
                </b>
              </div>
              <div className="screenItemContainer">
                <b>
                  <Icon
                    style={{ fontSize: "18px" }}
                    type="picture"
                  />{" "}
                  {t('Approximate impressions')}
                  <br />
                  <span className="primary span-description">
                    {(Math.round(display.shows_per_hour * (wednesdayMinutes/60)))} / {t('Day')}{" "}
                    <Tooltip
                      placement="right"
                      title={
                        <span>{t('It is an approximate value, because it depends on the days and rules you choose in your campaign. Once the campaign is created, you will get the exact impressions.')}</span>
                      }>
                      <Icon
                        style={{ fontSize: "14px", marginLeft:"10px"}}
                        type="info-circle"
                      />
                    </Tooltip>
                  </span>
                </b>
              </div>
              <div className="screenItemContainer">
                <b>
                  <Icon
                    style={{ fontSize: "18px" }}
                    type="schedule"
                  />{" "}
                  {t('Hours of Operation')}
                  <br/>
                  {display.working_hours && display.working_hours.length !== 0 ? (
                      <Tooltip
                        placement="right"
                        title={<span>{workingHoursList}</span>}>
                        <span className="primary span-description">
                          {t('List of days')}
                          <Icon
                            style={{ fontSize: "14px", marginLeft:"10px"}}
                            type="info-circle"
                          />
                        </span>
                      </Tooltip>
                  ) : (
                    <span className="primary span-description">
                    {t('Lit all day every day')}
                    </span>
                  )}
                  <br/>
                </b>
              </div>
              {display.format_settings && Object.keys(display.format_settings).some(format => display.format_settings[format]) ? (
                <div className="screenItemContainer">
                  <b>
                    <Icon
                      style={{ fontSize: "18px" }}
                      type="play-circle"
                    />{" "}
                    {t('Allowed ad format')}
                    <br />
                    <span className="primary span-description">
                      {Object.keys(display.format_settings)
                        .filter(format => display.format_settings[format])
                        .map(format => {
                          switch (format) {
                            case 'image':
                              return t('Image');
                            case 'video':
                              return t('Video');
                            case 'html':
                              return t('HTML');
                            default:
                              return format.charAt(0).toUpperCase() + format.slice(1);
                          }
                        })
                        .join(' - ')}
                    </span>
                    <br />
                  </b>
                </div>
              ) : null}
              <div className="screenItemContainer">
                <b>
                  <Icon
                    style={{ fontSize: "18px" }}
                    type="reload"
                  />{" "}
                  {t('Ad rotated')}
                  <br />
                  <span className="primary span-description">
                    {display.rotation === 90
                      ? `90° ${t('to the right')}`
                      : display.rotation === -90
                      ? `90° ${t('to the left')}`
                      : display.rotation === 0
                      ? t('Normal')
                      : `${display.rotation}°`}
                  </span>
                  <br />
                </b>
              </div>
            </Col>
          </Row>
          <Divider className="line-divider"/>
          <Row>
            <Col xs={24} sm={24} md={withForInfo} lg={withForInfo} xl={withForInfo}>
              <b>
              <h3 className="title-col important-text-information">
                  {this.props.programmatic === 'programmatic' ?
                    <>
                      {t('CPM spots')}
                      <Tooltip placement="bottomLeft" title={t('Cost per thousand spot')}>
                        <Icon style={{ fontSize: "14px", marginLeft:"10px"}} type="info-circle"/>
                      </Tooltip>
                    </>
                    :<>{t('Cost per day')}</>
                  }
                <br/>
                <div className="number-col-info primary">
                  <NumberFormat
                    value ={this.props.programmatic === 'programmatic' ?
                      display.smart_campaign_cpm
                      : (display.base_price_per_day ? display.base_price_per_day : display.price_per_day)
                    }
                    currency={this.props.currency}
                  />
                  {(((display.rank_discounts && display.rank_discounts.length > 0 ) &&
                     this.props.programmatic === 'traditional')) &&
                    <TagsDiscount display={display} currency={this.props.currency} color="#faad14"/>
                  }
                </div>
              </h3>
              <br />
              </b>{" "}
            </Col>
            {this.props.programmatic === 'programmatic' && (
              <Col xs={24} sm={24} md={withForInfo} lg={withForInfo} xl={withForInfo}>
                <b>
                  <h3 className="title-col important-text-information">
                      {this.props.programmatic === 'programmatic' ?
                        <>
                          {t('CPM impacts')}
                          <Tooltip placement="bottomLeft" title={t('Cost per thousand impacts')}>
                            <Icon style={{ fontSize: "14px", marginLeft:"5px"}} type="info-circle"/>
                          </Tooltip>
                        </>
                        :<>{t('Cost per day')}</>
                      }
                    <br/>
                    <div className="number-col-info primary">
                      <NumberFormat
                        value ={display.cpmi}
                        currency={this.props.currency}
                      />
                      {(((display.rank_discounts && display.rank_discounts.length > 0 ) &&
                        this.props.programmatic === 'traditional')) &&
                        <TagsDiscount display={display} currency={this.props.currency} color="#faad14"/>
                      }
                    </div>
                  </h3>
                <br/>
                </b>{" "}
              </Col>)
            }
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <b>
                <h3 className="title-col important-text-information">
                  {t('Restrictions')}
                  <br/>
                  <div className="primary" style={{fontSize: "1em"}}>
                    {display.constraints ?
                      <>{display.constraints}</>:<>{t('Without restrictions')}</>
                    }
                  </div>
                </h3>
              </b>
            </Col>
          </Row>
        </div>
      </Card>
    );
  }
}

export default withTranslation()(PrincipalInformationScreenComponent);
