import styled from "styled-components";
import Colors from "../../themes/Colors";

const CardGeneralHolder = styled.div`
  .cardGeneral {
    padding-left: 15px;
    padding-bottom: 20px;
    position: fixed;
    z-index: 996;
    top: 10px;
    left: 10px;
    height: auto;
    line-height: 30px;
    background-color: ${Colors.primary};
    font-size: 16px;
    text-align: center;
    color: aliceblue;
    border-radius: 8px;
    margin-bottom: 10px;

    @media only screen and (min-width: 576px) {
      top: ${({ hasRedistribute }) =>
        hasRedistribute
          ? 'calc(150px + 360px + 120px)'
          : 'calc(150px + 320px + 120px)'};
    }

    @media only screen and (min-width: 1000px) {
      top: ${({ hasRedistribute }) =>
        hasRedistribute
          ? 'calc(60px + 250px + 90px)'
          : 'calc(60px + 200px + 90px);'};
      max-width: 11%;
    }
    @media only screen and (max-width: 1400px) {
      max-width: 11%;
      .impacts-container {
        line-height: 20px;
      }
    }
    @media only screen and (max-width: 1200px) {
      position: inherit;
      max-width: 100%;
    }
    .buttonAudienceDetails {
      margin-top: 6px;
    }
  }

  .cartAudienceHidden {
    position: fixed;
    z-index: 2;
    top: calc(50% - 25px);
    right: -150px;
    height: 45px;
    width: 150px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: ${Colors.primary};
    font-size: 16px;
    font-weight: 600;
    @media only screen and (min-width: 768px) {
      top: calc(85px + 127px + 140px);
    }
    @media only screen and (min-width: 1000px) {
      top: calc(85px + 77px + 120px);
    }
  }
  .text-title {
    text-align: left;
    font-size: 22px;
    opacity: 0px;
    margin-top: 15px;
  }

  .infoDate {
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #D7EAFD;
  }
  .ant-calendar-picker {
    width: 80%;
  }
  .contentDate {
    text-align: justify;
  }
  .content-info {
    width: 55px;
    height: 32px;
    padding: 5px 6px 5px 6px;
    border-radius: 4px;
    background-color: #D7EAFD;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #3996F3;
  }

  `;

export default CardGeneralHolder;