import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Icon, Row, Tooltip, Typography, Button} from "antd";
import CartBudgetStyleHolder from "./Styles/cartBudgetStyle";
import NumberFormat from "./NumberFormat";
import { addCustomContent } from '../redux/DisplayRedux';

const { Text, Title } = Typography;

const CartBudgetComponent = ({
  stepsCurrent,
  updateByAmountBudget,
  amountBudget,
  realAmount,
  currentCampaign,
  isPaymentStatusApproved,
  editCampaignActive,
  currency,
  customContents,
  cart,
  updateRealAmount }) => {

  const { t } = useTranslation();
  const [calculatedBudget, setCalculatedBudget] = useState(0);
  const [currentCurrency, setCurrentCurrency] = useState(currency);

  const isDifferentMount = () => {
    const amountBudgetAux = Number(amountBudget);
    if (calculatedBudget > amountBudgetAux) return true;
    if (calculatedBudget <= (amountBudgetAux * 0.90)) return true;
    return false;
  }

  useEffect(() => {
    var newAmount = 0;
    customContents.forEach((relation) => {
      let cartSelect = null;
      let cpm = 0;
      if (editCampaignActive){
        cartSelect = currentCampaign.contents_displays.find(item => item.display_id === relation.displayId)
        cpm = cartSelect.cpm
      }
      if (!editCampaignActive || !cartSelect ) {
        cartSelect = cart.find((display) => display.id === relation.displayId)
        cpm = cartSelect.smart_campaign_cpm
      }

      if(!cartSelect) return
      newAmount = newAmount + (relation.promised_shows * (cpm / 1000));
    });

    if(newAmount !== realAmount) {
      updateRealAmount(newAmount)
    }
  }, []);

  useEffect(() => {
    setCalculatedBudget(Math.round(realAmount * 100) / 100)
    setCurrentCurrency(editCampaignActive ? currentCampaign.currency : currency);
    isDifferentMount();
  }, [realAmount, currency]);

  return (
    <CartBudgetStyleHolder>
      <div className="cartBudget">
        <Row
          type="flex"
          justify="space-between"
        >
          <Title
            level={3}
            className="text-title"
          >
            {t('Updated budget')}
          </Title>
        </Row>
        <Row>
          <Text>
            <NumberFormat
              value={calculatedBudget === 0 ? amountBudget : calculatedBudget}
              currency={currentCurrency}
            />
          </Text>
          { ( isDifferentMount() && (stepsCurrent !== 0) && !isPaymentStatusApproved ) && (
            <Tooltip
              placement="right"
              title={t('The initial amount was modified; you can redistribute the budget')}
            >
              <Icon
                type="exclamation-circle"
                theme="outlined"
                style={{color: "#fadb14eb", fontSize: "18px", marginLeft: "5px"}}
              />
            </Tooltip>
          )}
        </Row>
        <Row style={{textAlign: "center"}}>
          {((stepsCurrent !== 0) && !isPaymentStatusApproved ) && (
              <Tooltip
                className="appear-animation-without-movement"
                placement="right"
                title={t('Redistribute the new budget and recalculate spots (changes made to spots for each ad will be deleted)')}
              >
                <Button
                  className='groupFilterButton'
                  onClick={() => updateByAmountBudget(realAmount)}
                >
                  {t("Redistribute")}
                </Button>
              </Tooltip>
            )}
        </Row>
      </div>
    </CartBudgetStyleHolder>
  );
}

export default CartBudgetComponent;
