import { library } from "@fortawesome/fontawesome-svg-core";
import { set } from "lodash";
import lib from "redux-persist-seamless-immutable";
import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setDates: ["date_from", "date_to"],
  setBounds: ["bounds"],
  setPrice: ["prices"],
  setCoords: ["coords"],
  setMapZoom: ["zoom"],
  setShowsPerDayMin: ["shows_per_day_min"],
  setLocationType: ["location_type"],
  setLocationSubType: ["location_sub_type"],
  setSizeType: ["size_type"],
  setPage: ["page"],
  setPageCampaigns: ["pageCampaigns"],
  resetFilters: null,
  addDisplayToCart: ["display"],
  addListDisplayToCart: ["displays"],
  setDefaultContent: ["defaultContent"],
  clearCustomContents: null,
  addRelationDisplay: ["displayId", "relationId", "impressions", "subsidized_price"],
  addCustomContent: ["content", "displayId", "relationId", "typePreview"],
  toggleFillScreen: [
    "displayId",
    "relationId",
    "value",
    "typePreview",
    "customContents",
  ],
  updateFieldInCustomContents: [
    "id",
    "value",
    "valueName",
    "typePreview",
  ],
  updateFieldInContent: [
    "relationId",
    "content",
    "value",
    "valueName",
    "typePreview",
  ],
  updateRulesRelationScreen: ["displayId", "relationId", "newRules"],
  cloneRulesRelationScreen: ["rulesToClone"],
  cloneSpotsRelationScreen: ["promisedShowsToClone"],
  updateImpressionsDisplay: ["displayId", "relationId", "impressions", "promised_shows_update_in_transfer", "add_max_in_transfer"],
  updatePriceCartByDiscount: ["displayId", "price"],
  removeRelationFromCustomContents: ["displayId"],
  removeDisplayFromCart: ["displayId"],
  removeBundlesFromCart: ["displayIds", "listBundle"], // Delete a list of displays from cart
  updateBundles: ["listBundle"], // Update list of bundles
  removeRelationFromDisplay: ["displayId", "relationId"],
  removeDisplayOnCampaign: ["displayId"],
  removeRelationsOnCampaign: ["displayId", "contentId"],
  removeDisplaysCart: null, // initialize cart
  setParamsFromUrl: ["params"],
  setCampaignName: ["campaignName"],
  setToleranceResolution: ["toleranceResolution"],
  setToleranceResolutionInit: ["toleranceResolutionInit"],
  setCampaignDescription: ["campaignDescription"],
  setIsCampaignTest: ["isCampaignTest"],
  setIsCampaignPause: ["isCampaignPaused"],
  updateDefaultContent: ["content"],
  updateCustomContents: ["customContents"],
  updateDefaultContentRequest: ["id"],
  updateDefaultContentSuccess: ["content"],
  updateDefaultContentFailure: ["errors"],
  updateCustomContentRequest: ["id", "displayId", "relationId", "typePreview", "active"],
  updateContentCampaignRequest: ["content", "relation", "campaignId"],
  updateRelationCampaignRequest: ["content", "relation", "campaignId"],
  cleanUpdateSpots: null,
  updateContentCampaignSuccess: ["content", "relationId"],
  updateContentDisplayByContent: ["content", "display"],
  createNewContentSuccess: [],
  loadingContentInCampaign: ["status"],
  updateContentCampaignFailure: ["errors"],
  updateCustomContentSuccess: [
    "content",
    "displayId",
    "relationId",
    "typePreview",
  ],
  updatePromiseShowsInRelations: ["newCustomContents"],
  updateCustomContentFailure: ["errors"],
  clearCampaignData: null,
  getDisplaysCartSuccess: ["displays"],
  getDisplaysCartRequest: ["params"],
  getDisplaysCartFailure: ["errors"],
  getDisplaysRequest: ["params"],
  getDisplaysSuccess: ["displays", "perPage", "total"],
  getDisplaysFailure: ["errors"],
  getDisplayInfoRequest: ["id", "clientId", "currency", "invoice_issuing_country", "external_dsp_id"],
  getDisplaysInfoRequest: ["params"],
  getDisplaysInfoSuccess: ["displays"],
  getDisplaysInfoFailure: ["errors"],
  getDisplayInfoSuccess: ["display"],
  getDisplayInfoFailure: ["errors"],
  getExcelDisplaysRequest: ["params"],
  getExcelDisplaysSuccess: ["excel"],
  getExcelDisplaysFailure: ["errors"],
  uploadFileRequest: ["file"],
  uploadFileSuccess: ["fileNew"],
  uploadFileFailure: ["errors"],
  createCampaignRequest: ["params"],
  createCampaignSuccess: ["campaign"],
  createCampaignFailure: ["errors"],
  editCampaignSuccess: ["campaign"],
  editCampaignFailure: ["errors"],
  getCampaignsRequest: ["params"],
  getCampaignsSuccess: ["campaigns", "totalCampaigns"],
  getCampaignsFailure: ["errors"],
  getCampaignRequest: ["id"],
  getCampaignSuccess: ["campaign"],
  getCampaignFailure: ["errors"],
  deleteCampaignRequest: ["campaignId"],
  deleteCampaignSuccess: ["response", "campaignId"],
  deleteCampaignFailure: ["errors"],
  createReplicateCampaignRequest: ["campaignId", "params"],
  createReplicateCampaignSuccess: ["campaignId"],
  createReplicateCampaignFailure: ["errors"],
  requestSpecialCpmRequest: ["displayId", "requestedPrice", "currency"],
  requestSpecialCpmSuccess: null,
  requestSpecialCpmFailure: ["errors"],
  requestSpecialPriceRequest: ["displayId", "requestedPrice", "currency"],
  requestSpecialPriceSuccess: null,
  requestSpecialPriceFailure: ["errors"],
  changeStateUpdateContent: ["stateLoading"],
  addCustomContentFromCampaign: [
    "relationId",
    "indice_relation",
    "active",
    "displayId",
    "fill_screen",
    "isContentMixed",
    "promised_shows",
    "length",
    "content",
    "rules",
    "subsidized_price",
    "promised_shows_subsidized",
  ],
  activateEditCampaign: ["campaign"],
  editCampaignInactive: ["campaign"],
  editCampaignRequest: ["campaignId", "params"],
  editCampaignSuccess: ["campaign"],
  editCampaignFailure: ["errors"],
  editCampaignTotalPromisedShows: ["totalPromisedShows"],

  archiveCampaignRequest: ["campaignId", "params"],
  archiveCampaignSuccess: ["campaignId"],
  archiveCampaignFailure: ["errors"],

  getClientsRequest: ["userId"],
  getClientsSuccess: ["clients"],
  getClientsFailure: ["errors"],
  setClient: ["client"],
  setCircuit: ["isCircuit"],

  getExternalDspsRequest: [],
  getExternalDspsSuccess: ["dsps"],
  getExternalDspsFailure: ["errors"],
  setDsp: ["dsp"],
  clearDsp: [],

  getBrandsRequest: ["clientId"],
  getBrandsSuccess: ["brands"],
  getBrandsFailure: ["errors"],
  setBrand: ["brand"],
  setBrands: ["brands"],
  clearBrands: null,
  clearClients: null,

  setCountries: ["countries"],

  setProgrammatic: ["programmatic"],
  setBudget: ["amountBudget"],
  updateRealAmount: ["realAmount"],
  setAutomovilFilter: ["automovilFilter"],
  setShoppingFilter: ["shoppingFilter"],
  setServicesFilter: ["servicesFilter"],
  setTrainingFilter: ["trainingFilter"],
  setEducationFilter: ["educationFilter"],
  setBankFilter: ["bankFilter"],
  setTransportFilter: ["transportFilter"],
  setGovernmentFilter: ["governmentFilter"],
  setHotelFilter: ["hotelFilter"],
  setFoodFilter: ["foodFilter"],
  setHealthyFilter: ["healthyFilter"],
  setGenderWomanFilter: ["genderWomanFilter"],
  setGenderManFilter: ["genderManFilter"],
  setDefaultRules: ["defaultRules"],

  getQuotesRequest: ["userId", "params"],
  getQuotesSuccess: ["quotes"],
  getQuotesFailure: ["errors"],
  getQuoteRequest: ["token", "client", "brand", "cart", "dps"],
  getQuoteSuccess: ["quote"],
  getQuoteFailure: ["errors"],
  createQuoteRequest: ["quoteData"],
  createQuoteSuccess: ["quote"],
  createQuoteFailure: ["errors"],
  editQuoteRequest: ["quoteId", "quoteData"],
  editQuoteSuccess: ["quote"],
  editQuoteFailure: ["errors"],
  deleteQuoteRequest: ["quoteId"],
  deleteQuoteSuccess: ["response"],
  deleteQuoteFailure: ["errors"],
  setArchivedQuoteRequest: ["quoteId", "archived"],
  setArchivedQuoteSuccess: ["quoteId"],
  setArchivedQuoteFailure: ["errors"],

  displayReset: null,

  createPaymentRequest: ["campaignId", "paymentData"],
  createPaymentSuccess: ["paymentData"],
  createPaymentFailure: ["errors"],

  createPurchaseOrdersRequest: ["companyId", "campaignId"],
  createPurchaseOrdersSuccess: ["data"],
  createPurchaseOrdersFailure: ["errorsOdc"],

  // getMercadoPagoPaymentMethodsRequest: null,
  getMercadoPagoPaymentMethods: ["paymentMethods"],
  // getMercadoPagoPaymentMethodsFailure: ["errors"],

  getPaymentRequest: ["paymentId"],
  getPaymentSuccess: ["payment"],
  getPaymentFailure: ["errors"],

  deletePaymentRequest: ["paymentId"],
  deletePaymentSuccess: ["payment"],
  deletePaymentFailure: ["errors"],

  getCampaignPaymentsRequest: ["campaignId"],
  getCampaignPaymentsSuccess: ["payments"],
  getCampaignPaymentsFailure: ["errors"],

  createCouponRequest: ["campaignId", "code"],
  createCouponSuccess: ["couponData"],
  createCouponFailure: ["errors"],

  deleteCouponRequest: ["campaignId", "couponId"],
  deleteCouponSuccess: ["couponData"],
  deleteCouponFailure: ["errors"],

  getMpInitPointRequest: ["campaignId", "paymentData"],
  getMpInitPointSuccess: ["paymentData"],
  getMpInitPointFailure: ["errors"],

  getLastBillingInfoRequest: ["userId"],
  getLastBillingInfoSuccess: ["lastBillingInfo"],
  getLastBillingInfoFailure: ["errors"],

  getReportRequest: ["reportData"],
  getReportSuccess: ["reportToken"],
  getReportFailure: ["errors"],

  getProposalRequest: ["proposalData"],
  getProposalSuccess: ["proposalToken"],
  getProposalFailure: ["errors"],

  getSingleReportRequest: ["params"],
  getSingleReportSuccess: ["reportSingleData"],
  getSingleReportFailure: ["errors"],
  editSingleReport: ["contentIdOld", "contentIdNew", "displayId"],

  getAudienceRequest: ["data"],
  cleanAudience: null,
  getAudienceSuccess: ["audience"],
  getAudienceFailure: ["errors"],

  getAudienceDatsWhyRequest: ["data"],
  getAudienceDatsWhySuccess: ["audienceDatsWhy"],
  getAudienceDatsWhyFailure: ["errors", "data"],
  setAudienceDatsWhy: ["audienceDatsWhy"],

  getAudienceHoursOfDayRequest: ["data"],
  getAudienceHoursOfDaySuccess: ["audienceHoursOfDay"],
  getAudienceHoursOfDayFailure: ["errors", "data"],

  getAudienceDaysOfWeekRequest: ["data"],
  getAudienceDaysOfWeekSuccess: ["audienceDaysOfWeek"],
  getAudienceDaysOfWeekFailure: ["errors", "data"],

  getProposalAudienceRequest: ['data'],
  getProposalAudienceSuccess: ['proposalAudience'],
  getProposalAudienceFailure: ['errors'],

  setCanvaInit: ["canva"],

  setPoiFilters: ["poi_filters"],
  resetPoiFilters: null,

  getCurrencyExchangeRateRequest: [],
  getCurrencyExchangeRateSuccess: ["currencyExchangeRate"],
  getCurrencyExchangeRateFailure: ["errors"],

  getConversionRatesRequest: [],
  getConversionRatesSuccess: ["conversionRates"],
  getConversionRatesFailure: ["errors"],

  setLibraryContents:["libraryContents"],
  getContentsByCompanyRequest: ["companyId", "params"],
  getContentsByCompanySuccess: ["libraryContents"],
  getContentsByCompanyFailure: ["errors"],

  setListOfContentsInCampaign: ["contents"],
  cleanListOfContentsInCampaign: [],
  deleteContentFromCampaign: ["contentId"],
  remplaceContentFromCampaign:["contentId", "contentToReplace"],
  remplaceContentForUniqueVersion: ["contentId", "listDisplay", "contentToReplace"],
  remplaceContentFromRelation: ["contentId", "displayId", "contentToReplace"],
  updateListOfContentsInCampaign: ["content"],

  setListResolutionWithContent: ["listResolutionWithContent"],

  getCategoriesRequest: [],
  getCategoriesSuccess: ["categories"],
  getCategoriesFailure: ["errors"],
  setCategoriesId: ["categoriesId"],

  setLibraryRules: ["libraryRules"],
  setManagerDisplayFilter: ["managerDisplayFilter"],
  setManagerDisplaySelector: ["managerDisplaySelector"],
  disableManagerDisplayFilter: ["disabledManagerDisplayFilter"],
  cleanLibraryRules: [],
  setNewVersionCampaign: ["newVersionCampaign"],
});

export const DisplayTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  date_from: null,
  date_to: null,
  lat_ne: -32.86214,
  lat_sw: -32.9045304,
  lng_ne: -68.81727000000001,
  lng_sw: -68.89976819999998,
  mapZoom: 12,
  price_min: 0,
  price_max: 10000,
  shows_per_day_min: 1,
  location_type: [],
  location_sub_type: [],
  size_type: [],
  displays: [],
  customContents: [],
  defaultContent: {},
  currentDisplay: {
    id: null,
    name: "",
    resolution_width: 0,
    resolution_height: 0,
    shows_per_hour: 0,
    slot_length: 0,
    latitude: 0,
    longitude: 0,
  },
  currentCampaign: {
    id: null,
    name: "",
    contents_displays: [],
  },
  currentContentsDisplays: [],
  cart: [],
  page: 1,
  perPage: 20,
  total: 0,
  pageCampaigns: 1,
  campaigns: [],
  campaignName: "",
  campaignDescription: "",
  editCampaignActive: false,
  isCampaignPaused: false,
  isCampaignTest: false,
  isPaymentStatusApproved: false,
  editCampaign: {
    id: null,
    name: "",
    payment_status: "",
  },
  totalPromisedShows: 0,
  creatingPayment: false,
  couponData: {},
  creatingCoupon: false,
  deletingCoupon: false,
  couponErrors: {},
  paymentMethods: [],
  paymentData: {},
  payment: {},
  fetchingPayment: false,
  code: "",
  fetchingCampaign: false,
  loadingDeleteCampaign: false,
  creatingQuote: false,
  payments: [],
  fetchingPayments: false,
  deletingPayment: false,

  clients: [],
  brands: [],
  dsps: [],
  dsp: { id: null, name: 'Latinad', exchange: 'latinad' },

  loadingClients: false,
  loadingBrands: false,

  client: {},
  brand: {},

  countries: [],
  isCircuit: 0,
  programmatic: "traditional",
  amountBudget: 0,
  realAmount: 0,
  defaultRules: {},
  automovilFilter: false,
  shoppingFilter: false,
  servicesFilter: false,
  trainingFilter: false,
  edicationFilter: false,
  bankFilter: false,
  transportFilter: false,
  governmentFilter: false,
  hotelFilter: false,
  foodFilter: false,
  healthyFilter: false,
  genderWomanFilter: false,
  genderManFilter: false,
  lastBillingInfo: {},
  gettingBiilingInfo: false,
  reportToken: {},
  loadingReport: false,
  proposalToken: {},
  loadingPropoal: false,
  reportSingleData: {},
  loadingSingleReport: false,
  newSpotsChanged: [],
  loadingChangeSpot: false,
  loadingChangeSpotSuccess: false,
  loadingChangeSpotInCampign: false,
  audience: {},
  loadingAudience: false,
  excelDisplay: null,
  loadingExcel: false,
  audienceDatsWhy: null,
  loadingAudienceDatsWhy: true,
  loadingUploadFile: false,
  fileNew: {},
  canva: {},
  currency: "USD",
  poi_filters: [],
  fetchingOrders: false,
  ordersData: [],
  errorsOdc: null,
  fetchingaArchivedQuote: false,
  replicateCampaignId: null,
  replicatingCampaign: false,
  requestingSpecialCpm: false,
  requestSpecialCpmSuccessful: false,
  requestingSpecialPrice: false,
  requestSpecialPriceSuccessful: false,
  currencyExchangeRate: [],
  gettingCurrencyExchangeRate: false,
  listBundle: [],
  categories: [],
  categoriesId: null,
  editingCampaign: false,
  fetchingDisplaysInfo: false,
  libraryContents: [],
  listOfContentsInCampaign: [], // this are contents added to the campaign
  loadingListOfContentsInCampaign: false,
  listResolutionWithContent: [],
  libraryRules: [],
  isPendingCurrentCampaign: false,
  managerDisplayFilter: "dimensions",
  managerDisplaySelector: "",
  disabledManagerDisplayFilter: true,
  newVersionCampaign: false,
  toleranceResolution: 0,
  toleranceResolutionInit: 0,
  proposalAudience: null,
  loadingProposalAudience: false,
});

/* ------------- Reducers ------------- */
export const setDates = (state: Object, { date_from, date_to }: Object) =>
  state.merge({
    date_from: date_from,
    date_to: date_to,
  });

export const setBounds = (state: Object, { bounds }: Object) =>
  state.merge(bounds);

export const setPrice = (state: Object, { prices }: Object) =>
  state.merge({
    price_min: prices[0],
    price_max: prices[1],
  });

export const setCoords = (state: Object, { coords }: Object) =>
  state.merge({
    lat_ne: coords.lat_ne,
    lat_sw: coords.lat_sw,
    lng_ne: coords.lng_ne,
    lng_sw: coords.lng_sw,
  });

export const setMapZoom = (state: Object, { zoom }: Object) =>
  state.merge({
    mapZoom: zoom,
  });

export const setShowsPerDayMin = (
  state: Object,
  { shows_per_day_min }: Object
) =>
  state.merge({
    shows_per_day_min,
  });

export const setLocationType = (state: Object, { location_type }: Object) =>
  state.merge({
    location_type,
  });

export const setLocationSubType = (state: Object, { location_sub_type }: Object) =>
  state.merge({
    location_sub_type,
  });

export const setSizeType = (state: Object, { size_type }: Object) =>
  state.merge({
    size_type,
  });

export const setPoiFilters = (state: Object, { poi_filters }: Object) =>
  state.merge({
    poi_filters,
  });

export const resetPoiFilters = (state: Object) =>
  state.merge({
    poi_filters: [],
  });

export const setPage = (state: Object, { page }: Object) =>
  state.merge({
    page,
  });

export const setPageCampaigns = (state: Object, { pageCampaigns }: Object) =>
  state.merge({
    pageCampaigns,
  });

export const resetFilters = (state: Object, { page }: Object) =>
  state.merge({
    price_min: INITIAL_STATE.price_min,
    price_max: INITIAL_STATE.price_max,
    shows_per_day_min: INITIAL_STATE.shows_per_day_min,
    location_type: INITIAL_STATE.location_type,
    location_sub_type: INITIAL_STATE.location_sub_type,
    size_type: INITIAL_STATE.size_type,
    page: INITIAL_STATE.page,
  });

export const setDefaultContent = (state: Object, { defaultContent }: Object) =>
  state.merge({
    defaultContent,
    // customContents: [],
  });

export const clearCustomContents = (state: Object) =>
  state.merge({
    customContents: [],
  });

export const addRelationDisplay = (
  state: Object,
  { displayId, relationId, impressions , subsidized_price= 'no_subsidized_price'}: Object
) => {
  const newRelationDisplay = {
    displayId,
    fill_screen: false,
    relationId,
    promised_shows: impressions,
    subsidized_price: subsidized_price === 'subsidized_price',
  };

  return state.merge({
    customContents: [
      ...state.customContents.filter((c) => c.relationId !== relationId),
      Object.assign({}, newRelationDisplay),
    ],
  });
};

export const addCustomContent = (
  state: Object,
  { content, displayId, relationId, typePreview }: Object
) => {
  // const updatedRelation = state.customContents.find(
  //   c => c.displayId === displayId
  // ) || { displayId, fill_screen: false };
  if (typePreview === "multiple") {
    const arrayCustomContentsForDisplay = state.customContents.filter(
      (c) => c.displayId === displayId
    );
    let newArray = [...state.customContents];
    for (var i = 0; i < arrayCustomContentsForDisplay.length; i++) {
      const index = state.customContents.findIndex(
        (c) => c.relationId === arrayCustomContentsForDisplay[i].relationId
      );
      newArray[index] = { ...newArray[index], content: content };
    }
    return state.merge({
      customContents: newArray,
      fetching: false
    });
  } else {
    const index = state.customContents.findIndex(
      (c) => c.relationId === relationId
    );
    let newArray = [...state.customContents];
    newArray[index] = { ...newArray[index], content };

    return state.merge({
      customContents: newArray,
      fetching: false
    });
  }
};

export const addCustomContentFromCampaign = (
  state: Object,
  {
    relationId,
    indice_relation,
    active,
    displayId,
    fill_screen,
    isContentMixed,
    promised_shows,
    length,
    content,
    rules,
    subsidized_price,
    promised_shows_subsidized,
    content_name,
  }: Object
) => {
  const newRelationDisplay = {
    relationId: relationId,
    indice_relation,
    active,
    displayId: displayId,
    fill_screen: fill_screen,
    isContentMixed: isContentMixed,
    promised_shows: promised_shows,
    length: length,
    content: content,
    rules: rules,
    subsidized_price: subsidized_price,
    promised_shows_subsidized: promised_shows_subsidized,
    content_name: content_name,
  };
  return state.merge({
    customContents: [
      ...state.customContents.filter((c) => c.relationId !== relationId),
      Object.assign({}, newRelationDisplay),
    ],
  });
};

export const toggleFillScreen = (
  state: Object,
  { displayId, relationId, value, typePreview }: Object
) => {
  if (typePreview === "multiple") {
    const arrayCustomContentsForDisplay = state.customContents.filter(
      (c) => c.displayId === displayId
    );
    let newArray = [...state.customContents];
    for (var i = 0; i < arrayCustomContentsForDisplay.length; i++) {
      const index = state.customContents.findIndex(
        (c) => c.relationId === arrayCustomContentsForDisplay[i].relationId
      );
      newArray[index] = { ...newArray[index], fill_screen: value };
    }
    return state.merge({
      customContents: newArray,
    });
  } else {
    const index = state.customContents.findIndex(
      (c) => c.relationId === relationId
    );
    let newArray = [...state.customContents];
    newArray[index] = { ...newArray[index], fill_screen: value };
    return state.merge({
      customContents: newArray,
    });
  }
};

// Update field in customContents depend of valueName
export const updateFieldInCustomContents = (
  state: Object,
  { id, value, valueName, typePreview }: Object
) => {
  let newArray = [...state.customContents];
  if (typePreview === "multiple") {

    newArray = newArray.map(element => ({
      ...element,
      [valueName]: value
    }))

  } else if(typePreview === "display") {
    newArray = newArray.map(element => {
      if (element.displayId === id) {
        return {
          ...element,
          [valueName]: value
        };
      } else {
        return element;
      }
    });
  } else {
    const index = state.customContents.findIndex(
      (c) => c.relationId === id
    );

    if(valueName === "content") {
      value = { ...newArray[index].content, ...value }
    }
    newArray[index] = {
      ...newArray[index],
      [valueName]: value
    }
  }
  return state.merge({
    customContents: newArray,
  });
};

export const updateFieldInContent = (
  state: Object,
  { relationId, content, value, valueName, typePreview }: Object
) => {
  if (typePreview === "multiple") {
    const arrayCustomContentsForDisplay = state.customContents;
    let newArray = [...state.customContents];
    for (var i = 0; i < arrayCustomContentsForDisplay.length; i++) {
      if (valueName === "content_name") {
        newArray[i] = { ...newArray[i], content_name: value };
      }
    }
    return state.merge({
      customContents: newArray,
    });
  } else {
    const index = state.customContents.findIndex(
      (c) => c.relationId === relationId
    );
    let newArray = [...state.customContents];
    let newContent;
    if (valueName === "content_name") {
      newContent = { ...content, name: value };
      newArray[index] = { ...newArray[index], content: newContent };
    }
    return state.merge({
      customContents: newArray,
    });
  }
};

export const updateRulesRelationScreen = (
  state: Object,
  { displayId, relationId, newRules }: Object
) => {
  const index = state.customContents.findIndex(
    (c) => c.relationId === relationId
  );
  let newArray = [...state.customContents];
  newArray[index] = { ...newArray[index], rules: newRules };

  return state.merge({
    customContents: newArray,
  });
};

export const cloneRulesRelationScreen = (
  state: Object,
  { rulesToClone }: Object
) => {
  let newArray = [...state.customContents];
  for (var i = 0; i < newArray.length; i++) {
    newArray[i] = { ...newArray[i], rules: rulesToClone };
  }

  return state.merge({
    customContents: newArray,
  });
};

export const cloneSpotsRelationScreen = (
  state: Object,
  { promisedShowsToClone }: Object
) => {
  let newArray = [...state.customContents];
  for (var i = 0; i < newArray.length; i++) {
    newArray[i] = { ...newArray[i], promised_shows: promisedShowsToClone };

  }

  return state.merge({
    customContents: newArray,
  });
};

export const updatePriceCartByDiscount = (
  state: Object,
  { displayId, price }: Object
) => {
  const index = state.cart.findIndex(
    (c) => c.id === displayId
  );
  let newArray = [...state.cart];
  // when not have price for default, save the fist data in price_per_day
  if (newArray[index] && newArray[index].base_price_per_day == undefined) {
    let aux_base_price = newArray[index].price_per_day
    newArray[index] = { ...newArray[index], price_per_day: price, base_price_per_day: aux_base_price };
  } else {
    newArray[index] = { ...newArray[index], price_per_day: price };
  }
  return state.merge({
    cart: newArray,
  });
};

export const updateImpressionsDisplay = (
  state: Object,
  { displayId, relationId, impressions, promised_shows_update_in_transfer=0, add_max_in_transfer=0}: Object
) => {
  const index = state.customContents.findIndex(
    (c) => c.relationId === relationId
  );
  let newArray = [...state.customContents];
  let aux_promised_shows = promised_shows_update_in_transfer
  // This value is when transfer impressions from one display to another
  if (promised_shows_update_in_transfer === 0 && (newArray[index] && newArray[index].promised_shows_update_in_transfer)) {
    aux_promised_shows = newArray[index].promised_shows_update_in_transfer
  }
  newArray[index] = {
    ...newArray[index],
    promised_shows: impressions ,
    add_max_in_transfer: add_max_in_transfer,
    promised_shows_update_in_transfer: aux_promised_shows
  };

  var newAmount = 0;
  newArray.forEach((relation) => {
    newAmount =
      newAmount +
      (relation.promised_shows *
        (state.cart.find((display) => display.id === relation.displayId) &&
          state.cart.find((display) => display.id === relation.displayId)
            .smart_campaign_cpm)) /
      1000;
  });

  return state.merge({
    customContents: newArray,
    realAmount: newAmount,
  });
};

export const addDisplayToCart = (state: Object, { display }: Object) =>
  state.merge({
    cart: [...state.cart, display],
  });

export const addListDisplayToCart = (state: Object, { displays }: Object) =>
  state.merge({
    cart: [...state.cart, ...displays],
  });

export const removeRelationFromCustomContents = (
  state: Object,
  { displayId }: Object
) =>
  state.merge({
    customContents: state.customContents.filter(
      (c) => c.displayId !== displayId
    ),
  });

export const removeRelationFromDisplay = (
  state: Object,
  { displayId, relationId }: Object
) =>
  state.merge({
    customContents: state.customContents.filter(
      (c) => c.relationId !== relationId
    ),
  });

// Delete relations from all array necessary in new view
export const removeRelationsOnCampaign = (state: Object, { displayId, contentId }: Object) => {
  const { listResolutionWithContent, customContents } = state;

  const updatedListResolutionWithContent = listResolutionWithContent.map(item => ({
    ...item,
    contentsDisplays: item.contentsDisplays
    .filter(contentDisplay => !(contentDisplay.displayId === displayId && contentDisplay.contentId === contentId)),
  }));

  const newState = {
    listResolutionWithContent: updatedListResolutionWithContent,
    customContents: customContents.filter(c => !(c.displayId === displayId && c.content.id === contentId)),
  };

  return state.merge(newState);
};

export const removeDisplayOnCampaign = (state: Object, { displayId }: Object) => {
  const { listResolutionWithContent, customContents } = state;

  const updatedListResolutionWithContent = listResolutionWithContent.map(item => ({
    ...item,
    displays: item.displays.filter(display => display.id !== displayId),
    contentsDisplays: item.contentsDisplays.filter(contentDisplay => contentDisplay.displayId !== displayId),
  }));

  const newState = {
    listResolutionWithContent: updatedListResolutionWithContent,
    customContents: customContents.filter(c => c.displayId !== displayId),
  };

  return state.merge(newState);
};

export const getDisplaysCartRequest = (state: Object, { params }: Object) => {
  const newState = {
    errors: [],
    listBundle: params.listBundle ? params.listBundle : state.listBundle,
  };

  if (params.fetching !== undefined) {
    newState.fetching = params.fetching;
  }

 return state.merge(newState);
}

export const getDisplaysCartSuccess = (state: Object, { displays }: Object) =>
  state.merge({
    fetching: false,
    cart: [...displays]
  });

export const getDisplaysCartFailure = (
  state: Object,
  { errors }: Object
) =>
  state.merge({
    fetching: false,
    errors
  });

export const removeDisplayFromCart = (state: Object, { displayId }: Object) => {
  if (state.customContents) {
    return state.merge({
      cart: state.cart.filter((c) => c.id !== displayId),
      customContents: state.customContents.filter((c) => c.displayId !== displayId),
    });
  }

  return state.merge({
    cart: state.cart.filter((c) => c.id !== displayId),
  });
};

export const removeBundlesFromCart = (state: Object, { displayIds, listBundle }: Object) =>
  state.merge({
    cart: state.cart.filter((c) => !displayIds.includes(c.id)),
    listBundle: listBundle
  });
export const updateBundles = (state: Object, { listBundle }: Object) =>
  state.merge({
    listBundle: listBundle
  });
export const removeDisplaysCart = (state: Object) =>
  state.merge({
    cart: [],
    customContents: [],
    listBundle: [],
  });

export const setCampaignName = (state: Object, { campaignName }: Object) =>
  state.merge({
    campaignName,
  });

export const setToleranceResolution = (state: Object, { toleranceResolution }: Object) =>
state.merge({
  toleranceResolution,
});

export const setToleranceResolutionInit = (state: Object, { toleranceResolutionInit }: Object)  =>
state.merge({
  toleranceResolutionInit: toleranceResolutionInit,
});

export const setCampaignDescription = (
  state: Object,
  { campaignDescription }: Object
) =>
  state.merge({
    campaignDescription,
  });

export const setIsCampaignTest = (
  state: Object,
  { isCampaignTest }: Object
) => {
  return state.merge({
    isCampaignTest,
  });
};

export const setIsCampaignPause = (
  state: Object,
  { isCampaignPaused }: Object
) => {
  return state.merge({
    isCampaignPaused,
  });
};

export const setParamsFromUrl = (state: Object, { params }: Object) =>
  state.merge(params);

export const getDisplaysRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: [],
  });

export const getDisplaysSuccess = (
  state: Object,
  { displays, perPage, total }: Object
) =>
  state.merge({
    displays,
    perPage,
    total,
    fetching: false,
  });

export const getDisplaysFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors,
  });

export const updateDefaultContentRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: [],
  });

export const updateDefaultContentSuccess = (
  state: Object,
  { content }: Object
) =>
  state.merge({
    // customContents: [],
    defaultContent: content,
    fetching: false,
  });

export const updateDefaultContentFailure = (
  state: Object,
  { errors }: Object
) =>
  state.merge({
    fetching: false,
    errors,
  });

export const updateDefaultContent = (state: Object, { content }: Object) =>
  state.merge({
    defaultContent: content,
  });

export const updateCustomContents = (state: Object, { customContents }: Object) =>
state.merge({
  customContents: customContents,
});

export const updateCustomContentRequest = (
  state: Object,
  { displayId }: Object
) =>
  state.merge({
    fetching: true,
    errors: [],
  });

export const updatePromiseShowsInRelations = (state: Object, { newCustomContents}: Object) => {
    const arrayCustomContentsForDisplay = state.customContents.map((c) => {
      const newCustomContent = newCustomContents.find((c2) => c2.relationId === c.relationId);
      if(newCustomContent) return {...c, promised_shows: newCustomContent.promised_shows};
      return c
    });
    return state.merge({
      customContents: arrayCustomContentsForDisplay,
    });
}

export const updateCustomContentSuccess = (
  state: Object,
  { content, displayId, relationId, typePreview }: Object
) => {
  // const updatedDisplay = state.customContents.find(
  //   c => c.displayId === displayId
  // );
  if (typePreview === "multiple") {
    const arrayCustomContentsForDisplay = state.customContents.filter(
      (c) => c.displayId === displayId
    );
    let newArray = [...state.customContents];
    for (var i = 0; i < arrayCustomContentsForDisplay.length; i++) {
      const index = state.customContents.findIndex(
        (c) => c.relationId === arrayCustomContentsForDisplay[i].relationId
      );
      newArray[index] = { ...newArray[index], content: content };
    }
    return state.merge({
      customContents: newArray,
      fetching: false,
    });
  } else {
    const index = state.customContents.findIndex(
      (c) => c.relationId === relationId
    );
    let newArray = [...state.customContents];
    newArray[index] = { ...newArray[index], content };
    return state.merge({
      customContents: newArray,
      fetching: false,
    });
  }
};

export const updateCustomContentFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors,
  });

export const changeStateUpdateContent = (
  state: Object,
  { stateLoading }: Object
) =>
  state.merge({
    loadingChangeSpot: stateLoading,
  });

export const updateContentCampaignRequest = (
  state: Object,
  { content, relation, campaignId }: Object
) =>
  state.merge({
    loadingChangeSpot: true,
    loadingChangeSpotSuccess: relation,
    newSpotsChanged: [],
    errors: [],
  });

export const updateRelationCampaignRequest = (
  state: Object,
  { relation }: Object
) =>
  state.merge({
    loadingChangeSpot: true,
    loadingChangeSpotSuccess: relation,
    newSpotsChanged: [],
    errors: [],
  });

export const cleanUpdateSpots = (state: Object) =>
  state.merge({
    loadingChangeSpotSuccess: false,
    newSpotsChanged: [],
  });

export const updateContentDisplayByContent = (
  state: Object,
  { content, display }: Object
) => {
  const index = state.currentCampaign.contents_displays.findIndex(
    (c) => c.display_id === display.display_id && c.content_id === display.content_id
  );
  let newArray = [...state.currentCampaign.contents_displays];
  newArray[index] = { ...newArray[index], ...content };

  return state.merge({
    currentCampaign: {
      ...state.currentCampaign,
      contents_displays: newArray,
    },
  });
}

export const updateContentCampaignSuccess = (
  state: Object,
  { content, relationId }: Object
) => {
  return state.merge({
    // changeNewSpot: content,
    loadingChangeSpot: false,
    loadingChangeSpotSuccess: null,
    newSpotsChanged: [
      ...state.newSpotsChanged,
      Object.assign({}, { content, relation_id: relationId }),
    ],
  });
}

export const updateContentCampaignFailure = (
  state: Object,
  { errors }: Object
) =>
  state.merge({
    loadingChangeSpot: false,
    errors,
  });

export const clearCampaignData = (state: Object) =>
  state.merge({
    customContents: [],
    defaultContent: {},
    campaignName: "",
    toleranceResolution: 0,
    campaignDescription: "",
    isCampaignTest: false,
    isCampaignPaused: false,
    listBundle: [],
    categoriesId: null,
    listOfContentsInCampaign: [],
    listResolutionWithContent: [],
    cart: [],
  });

// Stop the query about the content change 
export const createNewContentSuccess = (state: Object, {}: Object) =>
  state.merge({
    loadingChangeSpot: false,
  });

// Stop the query about the content change 
export const loadingContentInCampaign = (state: Object, {status}: Object) =>
  state.merge({
    loadingChangeSpotInCampign: status,
  });

export const getDisplayInfoRequest = (state: Object) =>
  state.merge({
    fetchingDisplayInfo: true,
    errors: [],
  });

export const getDisplayInfoSuccess = (state: Object, { display }: Object) =>
  state.merge({
    currentDisplay: display,
    fetchingDisplayInfo: false,
    currentCampaign: {
      ...state.currentCampaign,
      displays:
        state.currentCampaign && state.currentCampaign.displays
          ? [...state.currentCampaign.displays, display]
          : [display],
    },
  });

export const getDisplayInfoFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetchingDisplayInfo: false,
    errors,
  });

export const getDisplaysInfoRequest = (state: Object) =>
  state.merge({
    fetchingDisplaysInfo: true,
    errors: [],
  });

export const getDisplaysInfoSuccess = (state: Object, { displays }: Object) =>
  state.merge({
    currentDisplay: displays.length > 1 ? displays[0] : displays,
    fetchingDisplaysInfo: false,
    cart: [...displays],
    currentCampaign: {
      ...state.currentCampaign,
      displays: [...displays]
    },
  });

export const getDisplaysInfoFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetchingDisplaysInfo: false,
    errors,
  });

export const getExcelDisplaysRequest= (state: Object, { params }: Object) =>
  state.merge({
    loadingExcel: true,
  });

export const getExcelDisplaysSuccess = (state: Object, { excel }: Object) =>
  state.merge({
    loadingExcel: false,
    excelDisplay: excel,
  });

export const getExcelDisplaysFailure = (state: Object, { errors }: Object) =>
  state.merge({
    loadingExcel: false,
    excelDisplay: null,
    errors,
  });

export const uploadFileRequest = (state: Object) =>
  state.merge({
    loadingUploadFile: true,
    errors: [],
  });

export const uploadFileSuccess = (state: Object, { fileNew }: Object) =>
  state.merge({
    fileNew,
    loadingUploadFile: false,
  });

export const uploadFileFailure = (state: Object, { errors }: Object) =>
  state.merge({
    loadingUploadFile: false,
    errors,
  });

export const createCampaignRequest = (state: Object) =>
  state.merge({
    creatingCampaign: true,
    errors: [],
  });

export const createCampaignSuccess = (state: Object, { campaign }: Object) => {
  let updatedState = state.merge({
    campaign,
    creatingCampaign: false,
  });

  if(!state.newVersionCampaign){
    updatedState = updatedState.merge({
      cart: [],
      customContents: [],
      defaultContent: {},
      campaignName: "",
      toleranceResolution: 0,
      campaignDescription: "",
      isCampaignTest: false,
      categoriesId: null
    });
  }
  return updatedState;
};

export const createCampaignFailure = (state: Object, { errors }: Object) =>
  state.merge({
    creatingCampaign: false,
    errors,
  });

export const getCampaignsRequest = (state: Object) =>
  state.merge({
    editingCampaign: false,
    fetching: true,
    editingCampaign: false,
    errors: [],
  });

export const createReplicateCampaignRequest = (state: Object) =>
  state.merge({
    replicateCampaignId: null,
    replicatingCampaign: true,
    errors: [],
  });

export const createReplicateCampaignSuccess = (state: Object, { campaignId }: Object) =>
  state.merge({
    replicateCampaignId: campaignId,
    replicatingCampaign: false,
    errors: [],
  });

export const createReplicateCampaignFailure = (state: Object, { errors }: Object) =>
  state.merge({
    replicatingCampaign: false,
    errors,
  });

export const requestSpecialCpmRequest = (state: Object) =>
  state.merge({
    requestingSpecialCpm: true,
  });

export const requestSpecialCpmSuccess = (state: Object) =>
  state.merge({
    requestingSpecialCpm: false,
    requestSpecialCpmSuccessful: true,
    errors: [],
  });

export const requestSpecialCpmFailure = (state: Object, { errors }: Object) =>
  state.merge({
    requestingSpecialCpm: false,
    requestSpecialCpmSuccessful: false,
    errors,
  });

export const requestSpecialPriceRequest = (state: Object) =>
  state.merge({
    requestingSpecialPrice: true,
  });

export const requestSpecialPriceSuccess = (state: Object) =>
  state.merge({
    requestingSpecialPrice: false,
    requestSpecialPriceSuccessful: true,
    errors: [],
  });

export const requestSpecialPriceFailure = (state: Object, { errors }: Object) =>
  state.merge({
    requestingSpecialPrice: false,
    requestSpecialPriceSuccessful: false,
    errors,
  });

export const getCampaignsSuccess = (
  state: Object,
  { campaigns, totalCampaigns }: Object
) =>
  state.merge({
    campaigns,
    totalCampaigns,
    fetching: false,
  });
export const getCampaignsFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    campaigns: [],
    errors,
  });

export const getCampaignRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: [],
    currentCampaign: null,
    currentContentsDisplays: null,
    fetchingCampaign: true,
  });

export const getCampaignSuccess = (state: Object, { campaign }: Object) =>
  state.merge({
    currentCampaign: campaign,
    isPendingCurrentCampaign: campaign.payment_status === "pending" ||
      campaign.payment_status === "cancelled" ||
      campaign.payment_status === "rejected" ||
      campaign.payment_status === "cancelledin_mediation" ||
      campaign.payment_status === "charged_back" ||
      campaign.payment_status === "in_process",
    fetching: false,
    fetchingCampaign: false,
  });

export const getCampaignFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    fetchingCampaign: false,
    errors,
  });

export const deleteCampaignRequest = (state: Object) =>
  state.merge({
    loadingDeleteCampaign: true,
    response: [],
    errors: [],
  });

export const deleteCampaignSuccess = (
  state: Object,
  { response, campaignId }: Object
) =>
  state.merge({
    loadingDeleteCampaign: false,
    response,
    campaigns: state.campaigns.filter((c) => c.id !== campaignId),
  });

export const deleteCampaignFailure = (state: Object, { errors }: Object) =>
  state.merge({
    loadingDeleteCampaign: false,
    errors,
  });

export const activateEditCampaign = (state: Object, { campaign }: Object) => {

  const contentsDisplays = [];
  campaign.contents_displays.forEach(item => {
      if (item.content_child_contents) {
          item.content_child_contents.forEach(child => {
              contentsDisplays.push({ displayId: item.display_id, contentId: child.id });
          });
      }else {
        contentsDisplays.push({ displayId: item.display_id, contentId: item.content_id });
      }
  });

  let updatedState = state.merge({
    editCampaignActive: true,
    editCampaign: {
      id: campaign.id,
      name: campaign.name,
      description: campaign.description,
      payment_status: campaign.payment_status,
    },
    isPaymentStatusApproved: campaign.payment_status === 'approved',
    isCampaignTest: !!campaign.is_test,
    currentCampaign: campaign,
    currentContentsDisplays: contentsDisplays,
    campaignName: campaign.name,
    campaignDescription: campaign.description,
    dsp: campaign.external_dsp,
    categoriesId: campaign.category ? campaign.category.id : null,
    brand: campaign.brand,
    client: campaign.client,
    libraryRules: [],
  });
  return updatedState;
};

export const setNewVersionCampaign = (state: Object, { newVersionCampaign }) =>
  state.merge({
    newVersionCampaign: newVersionCampaign,
  });

export const editCampaignInactive = (state: Object, { campaign }: Object) =>
  state.merge({
    editCampaignActive: false,
    isPaymentStatusApproved: false,
  });

export const editCampaignRequest = (state: Object) =>
  state.merge({
    editingCampaign: true,
    errors: [],
  });

export const editCampaignSuccess = (state: Object, { campaign }: Object) => {
  // This is necesary because the endpoint not return the data complete

  const campaignAux = {
    ...campaign,
    contents_displays: campaign.contents_displays.map((content, index) => ({
      ...state.currentCampaign.contents_displays[index],
      ...content,
    })),
  };

  let updatedState = state.merge({
    campaign,
    editingCampaign: false,
    currentCampaign: campaignAux,
  })

  if (!state.newVersionCampaign) {
    updatedState = updatedState.merge({
      currentCampaign: campaignAux,
      cart: [],
      customContents: [],
      defaultContent: {},
      campaignName: "",
      toleranceResolution: 0,
      libraryContents: [],
      listOfContentsInCampaign: [],
    });
  }

  return updatedState;
};

export const editCampaignFailure = (state: Object, { errors }: Object) =>
  state.merge({
    editingCampaign: false,
    errors,
  });

// Archived campaigns
export const archiveCampaignRequest = (state: Object) =>
  state.merge({
    loadingArchiveCampaign: true,
  });

export const archiveCampaignSuccess = (
  state: Object,
  { campaignId }: Object
) =>
  state.merge({
    loadingArchiveCampaign: false,
    campaigns: state.campaigns.filter((c) => c.id !== campaignId),
  });

export const archiveCampaignFailure = (state: Object, { errors }: Object) =>
  state.merge({
    loadingArchiveCampaign: false,
    errors,
  });



export const editCampaignTotalPromisedShows = (
  state: Object,
  { totalPromisedShows }: Object
) =>
  state.merge({
    totalPromisedShows: totalPromisedShows,
  });

export const getClientsRequest = (state: Object) =>
  state.merge({
    loadingClients: true,
    response: [],
    errors: [],
  });

export const getClientsSuccess = (state: Object, { clients }: Object) =>
  state.merge({
    clients,
    loadingClients: false,
  });

export const getClientsFailure = (state: Object, { errors }: Object) =>
  state.merge({
    loadingClients: false,
    errors,
  });

export const setCircuit = (state: Object, { isCircuit }: Object) =>
  state.merge({
    isCircuit: isCircuit,
  });

export const setClient = (state: Object, { client }: Object) =>
  state.merge({
    client: client,
    brands: [],
    brand: {},
  });

export const getExternalDspsRequest = (state: Object) =>
  state.merge({
    loading: true,
    response: [],
    errors: [],
  });
export const getExternalDspsSuccess = (state: Object, { dsps }: Object) =>
  state.merge({
    dsps,
    loading: false,
  });
export const getExternalDspsFailure = (state: Object, { errors }: Object) =>
  state.merge({
    loading: false,
    errors,
  });
export const setDsp = (state: Object, { dsp }: Object) =>
  state.merge({
    dsp: dsp,
  });
export const clearDsp = (state: Object) =>
  state.merge({
    dsp: {},
  });

export const setBrands = (state: Object, { brands }: Object) =>
  state.merge({
    brands: brands,
  });

export const setBrand = (state: Object, { brand }: Object) =>
  state.merge({
    brand: brand,
  });

export const getBrandsRequest = (state: Object) =>
  state.merge({
    loadingBrands: true,
    response: [],
    errors: [],
  });

export const getBrandsSuccess = (state: Object, { brands }: Object) =>
  state.merge({
    brands,
    loadingBrands: false,
  });

export const getBrandsFailure = (state: Object, { errors }: Object) =>
  state.merge({
    loadingBrands: false,
    errors,
  });

export const clearBrands = (state: Object) =>
  state.merge({
    brands: [],
    brand: {},
  });

export const clearClients = (state: Object) =>
  state.merge({
    clients: [],
    client: {},
  });

export const setCountries = (state: Object, { countries }: Object) =>
  state.merge({
    countries: countries,
  });

export const setProgrammatic = (state: Object, { programmatic }: Object) =>
  state.merge({
    programmatic: programmatic,
  });

export const setBudget = (state: Object, { amountBudget }: Object) =>
  state.merge({
    realAmount: amountBudget,
    amountBudget: amountBudget,
  });

export const updateRealAmount = (state: Object, { realAmount }: Object) =>
  state.merge({
    realAmount: realAmount,
  });

export const setAutomovilFilter = (
  state: Object,
  { automovilFilter }: Object
) =>
  state.merge({
    automovilFilter: !automovilFilter,
  });
export const setShoppingFilter = (state: Object, { shoppingFilter }: Object) =>
  state.merge({
    shoppingFilter: !shoppingFilter,
  });
export const setServicesFilter = (state: Object, { servicesFilter }: Object) =>
  state.merge({
    servicesFilter: !servicesFilter,
  });
export const setTrainingFilter = (state: Object, { trainingFilter }: Object) =>
  state.merge({
    trainingFilter: !trainingFilter,
  });
export const setEducationFilter = (
  state: Object,
  { educationFilter }: Object
) =>
  state.merge({
    educationFilter: !educationFilter,
  });
export const setBankFilter = (state: Object, { bankFilter }: Object) =>
  state.merge({
    bankFilter: !bankFilter,
  });
export const setTransportFilter = (
  state: Object,
  { transportFilter }: Object
) =>
  state.merge({
    transportFilter: !transportFilter,
  });
export const setGovernmentFilter = (
  state: Object,
  { governmentFilter }: Object
) =>
  state.merge({
    governmentFilter: !governmentFilter,
  });
export const setHotelFilter = (state: Object, { hotelFilter }: Object) =>
  state.merge({
    hotelFilter: !hotelFilter,
  });
export const setFoodFilter = (state: Object, { foodFilter }: Object) =>
  state.merge({
    foodFilter: !foodFilter,
  });
export const setHealthyFilter = (state: Object, { healthyFilter }: Object) =>
  state.merge({
    healthyFilter: !healthyFilter,
  });

export const setGenderWomanFilter = (
  state: Object,
  { genderWomanFilter }: Object
) =>
  state.merge({
    genderWomanFilter: !genderWomanFilter,
  });
export const setGenderManFilter = (
  state: Object,
  { genderManFilter }: Object
) =>
  state.merge({
    genderManFilter: !genderManFilter,
  });

export const setDefaultRules = (state: Object, { defaultRules }: Object) =>
  state.merge({
    defaultRules: defaultRules,
  });

export const getQuotesRequest = (state: Object) =>
  state.merge({
    fetching: true,
    response: [],
    errors: [],
  });

export const getQuotesSuccess = (state: Object, { quotes }: Object) =>
  state.merge({
    quotes,
    fetching: false,
  });

export const getQuotesFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors,
  });

export const getQuoteRequest = (state: Object, { client, brand, dsp }: Object) =>
  state.merge({
    dsp: dsp,
    client: client,
    brand: brand,
    fetchingQuote: true,
    errors: [],
  });

export const getQuoteSuccess = (state: Object, { quote }: Object) =>
  state.merge({
    quote,
    amountBudget: quote.budget,
    programmatic: quote.budget ? "programmatic" : "traditional",
    fetchingQuote: false,
  });

export const getQuoteFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetchingQuote: false,
    errors,
  });

export const createQuoteRequest = (state: Object) =>
  state.merge({
    creatingQuote: true,
    response: [],
    errors: [],
  });

export const createQuoteSuccess = (state: Object, { quote }: Object) =>
  state.merge({
    creatingQuote: false,
    quote,
  });

export const createQuoteFailure = (state: Object, { errors }: Object) =>
  state.merge({
    creatingQuote: false,
    errors,
  });

  export const editQuoteRequest = (state: Object) =>
    state.merge({
      editingQuote: true,
      response: [],
      errors: [],
      editQuoteSuccess: false,
      editQuoteFailure: false,
    });

  export const editQuoteSuccess = (state: Object, { quote }: Object) =>
    state.merge({
      editingQuote: false,
      editQuoteSuccess: true,
      editQuoteFailure: false,
      quote,
    });

  export const editQuoteFailure = (state: Object, { errors }: Object) =>
    state.merge({
      editingQuote: false,
      editQuoteSuccess: false,
      editQuoteFailure: true,
      errors,
    });

export const deleteQuoteRequest = (state: Object) =>
  state.merge({
    fetching: true,
    fetchingDeleteQuote: true,
    response: [],
    errors: [],
  });

export const deleteQuoteSuccess = (state: Object, { response }: Object) =>
  state.merge({
    fetching: false,
    fetchingDeleteQuote: false,
    response,
  });

export const deleteQuoteFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    fetchingDeleteQuote: false,
    errors,
  });

export const setArchivedQuoteRequest = (state: Object) =>
  state.merge({
    fetching: true,
    fetchingArchivedQuote: true,
    response: [],
    errors: [],
  });
export const setArchivedQuoteSuccess = (state: Object, { response }: Object) =>
  state.merge({
    fetching: false,
    fetchingArchivedQuote: false,
    response,
  });
export const setArchivedQuoteFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    fetchingArchivedQuote: false,
    errors,
  });

export const displayReset = (state: Object) => INITIAL_STATE;

// export const getMercadoPagoPaymentMethodsRequest = (state: Object) =>
//   state.merge({
//     fetching: true
//   });

export const getMercadoPagoPaymentMethods = (state: Object) =>
  state.merge({
    fetching: false,
    paymentMethods: [
      {
        id: "transfer",
        name: "Bank transfer",
        min_allowed_amount: 0,
        max_allowed_amount: 50000000,
        type: "other",
      },
      {
        id: "cash",
        name: "Cash",
        min_allowed_amount: 0,
        max_allowed_amount: 50000000,
        type: "other",
      },
      {
        id: "cheque",
        name: "Cheque",
        min_allowed_amount: 1,
        max_allowed_amount: 50000000,
        type: "other",
      },
      {
        id: "credit_account",
        name: "Credit account",
        min_allowed_amount: 1,
        max_allowed_amount: 50000000,
        type: "other"
      },
      {
        id: "visa",
        max_allowed_amount: 1500000,
        name: "visa",
        min_allowed_amount: 1,
        type: "credit_card"
      }
    ]
  });

// export const getMercadoPagoPaymentMethodsFailure = (
//   state: Object,
//   { errors }
// ) =>
//   state.merge({
//     fetching: false,
//     errors
//   });

export const createPaymentRequest = (state: Object) =>
  state.merge({
    creatingPayment: true,
    errors: {},
    paymentData: {},
  });

export const createPaymentSuccess = (state: Object, { paymentData }) =>
  state.merge({
    creatingPayment: false,
    paymentData,
  });

export const createPaymentFailure = (state: Object, { errors }) =>
  state.merge({
    creatingPayment: false,
    errors,
  });

export const createPurchaseOrdersRequest = (state: Object) =>
  state.merge({
    fetchingOrders: true,
    ordersData: {},
    errorsOdc: null,
  });

export const createPurchaseOrdersSuccess = (state: Object, { ordersData }) =>
  state.merge({
    fetchingOrders: false,
    ordersData,
  });

export const createPurchaseOrdersFailure = (state: Object, { errorsOdc }) =>
  state.merge({
    fetchingOrders: false,
    errorsOdc,
  });

export const getPaymentRequest = (state: Object) =>
  state.merge({
    fetchingPayment: true,
    errors: [],
    currentPayment: null,
  });

export const getPaymentSuccess = (state: Object, { payment }) =>
  state.merge({
    payment,
    paymentData: payment,
    fetchingPayment: false,
  });

export const getPaymentFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetchingPayment: false,
    errors,
  });

export const deletePaymentRequest = (state: Object, { paymentId }) =>
  state.merge({
    deletingPayment: true,
    errors: {},
    paymentId,
  });

export const deletePaymentSuccess = (state: Object) =>
  state.merge({
    deletingPayment: false,
    payments: state.payments.map((p) => {
      if (p.id === state.paymentId) {
        return {
          ...p,
          status: "cancelled",
        };
      }

      return p;
    }),
    paymentId: null,
  });

export const deletePaymentFailure = (state: Object, { errors }) =>
  state.merge({
    deletingPayment: false,
    errors,
    paymentId: null,
  });

export const getCampaignPaymentsRequest = (state: Object) =>
  state.merge({
    fetchingPayments: true,
    errors: [],
  });

export const getCampaignPaymentsSuccess = (state: Object, { payments }) =>
  state.merge({
    fetchingPayments: false,
    payments,
    paymentData: payments,
  });

export const getCampaignPaymentsFailure = (state: Object, { errors }) =>
  state.merge({
    fetchingPayments: false,
    errors,
  });

export const createCouponRequest = (state: Object) =>
  state.merge({
    creatingCoupon: true,
    errors: {},
    code: {},
  });

export const createCouponSuccess = (state: Object, { couponData }) =>
  state.merge({
    creatingCoupon: false,
    couponData,
    couponErrors: {},
  });

export const createCouponFailure = (state: Object, { errors }: Object) =>
  state.merge({
    creatingCoupon: false,
    couponData: {},
    couponErrors: errors,
  });

export const deleteCouponRequest = (state: Object) =>
  state.merge({
    deletingCoupon: true,
    errors: {},
    code: {},
  });

export const deleteCouponSuccess = (state: Object, { couponData }) =>
  state.merge({
    deletingCoupon: false,
    couponData,
    couponErrors: {},
  });

export const deleteCouponFailure = (state: Object, { errors }: Object) =>
  state.merge({
    deletingCoupon: false,
    couponData: {},
    couponErrors: errors,
  });

export const getMpInitPointRequest = (state: Object) =>
  state.merge({
    creatingPayment: true,
    errors: [],
    paymentData: {},
  });

export const getMpInitPointSuccess = (state: Object, { paymentData }) =>
  state.merge({
    creatingPayment: false,
    paymentData,
  });

export const getMpInitPointFailure = (state: Object, { errors }) =>
  state.merge({
    // creatingPayment: false,
    errors,
  });

export const getReportRequest = (state: Object) =>
  state.merge({
    loadingReport: true,
    reportToken: {},
    errors: [],
  });

export const getReportSuccess = (state: Object, { reportToken }) =>
  state.merge({
    loadingReport: false,
    reportToken,
  });

export const getReportFailure = (state: Object, { errors }) =>
  state.merge({
    loadingReport: false,
    errors,
  });

export const getProposalRequest = (state: Object) =>
  state.merge({
    loadingProposal: true,
    proposalToken: {},
    errors: [],
  });

export const getProposalSuccess = (state: Object, { proposalToken }) =>
  state.merge({
    loadingProposal: false,
    proposalToken,
  });

export const getProposalFailure = (state: Object, { errors }) =>
  state.merge({
    loadingProposal: false,
    errors,
  });

export const getSingleReportRequest = (state: Object) =>
  state.merge({
    loadingSingleReport: true,
    errors: [],
  });

export const getSingleReportSuccess = (state: Object, { reportSingleData }) =>
  state.merge({
    loadingSingleReport: false,
    reportSingleData,
  });

export const getSingleReportFailure = (state: Object, { errors }) =>
  state.merge({
    loadingSingleReport: false,
    errors,
  });

export const editSingleReport = (
  state: Object,
  { contentIdOld, contentIdNew, displayId }: Object
) => {
  // const updatedRelation = state.customContents.find(
  //   c => c.displayId === displayId
  // ) || { displayId, fill_screen: false };
  // if (typePreview === 'multiple') {
  // const customContentsReportsForDisplay = state.reportSingleData.report.filter(c => c.content === contentIdOld && c.display === displayId)
  // console.log(customContentsReportsForDisplay);
  // let newArray = [...state.customContents]
  // for (var i = 0; i < arrayCustomContentsForDisplay.length; i++) {
  //   const index = state.customContents.findIndex(c => c.relationId === arrayCustomContentsForDisplay[i].relationId)
  //   newArray[index] = {...newArray[index], content: content }
  // }
  // return state.merge({
  //   customContents: newArray
  // });
  // } else {

  const index = state.reportSingleData.report
    ? state.reportSingleData.report.findIndex(
      (c) => c.content === contentIdOld && c.display === displayId
    )
    : null;
  if (index && index >= 0) {
    var newArray = [...state.reportSingleData.report];
    newArray[index] = { ...newArray[index], content: contentIdNew };
    let newState = { ...state.reportSingleData.report, report: newArray };
    return state.merge({
      reportSingleData: newState,
    });
  } else {
    return state.merge({
      reportSingleData: state.reportSingleData,
    });
  }
  // }
};

export const getLastBillingInfoRequest = (state: Object) =>
  state.merge({
    gettingBiilingInfo: true,
    errors: [],
  });

export const getLastBillingInfoSuccess = (state: Object, { lastBillingInfo }) =>
  state.merge({
    gettingBiilingInfo: false,
    lastBillingInfo,
  });

export const getLastBillingInfoFailure = (state: Object, { errors }) =>
  state.merge({
    gettingBiilingInfo: false,
    // errors,
  });

export const getAudienceRequest = (state: Object) =>
  state.merge({
    loadingAudience: true,
    // audience: {},
    errors: [],
  });

export const cleanAudience = (state: Object) =>
  state.merge({
    loadingAudience: false,
    audience: {},
    errors: [],
  });

export const getAudienceSuccess = (state: Object, { audience }: Object) =>
  state.merge({
    loadingAudience: false,
    audience,
  });

export const getAudienceFailure = (state: Object, { errors }: Object) =>
  state.merge({
    loadingAudience: false,
    errors,
  });

export const getAudienceDatsWhyRequest = (state: Object) =>
  state.merge({
    loadingAudienceDatsWhy: true,
    errors: [],
  });

export const setAudienceDatsWhy = (
  state: Object,
  { audienceDatsWhy }: Object
) =>
  state.merge({
    loadingAudienceDatsWhy: false,
    audienceDatsWhy,
  });

export const getAudienceDatsWhySuccess = (
  state: Object,
  { audienceDatsWhy }: Object
) =>
  state.merge({
    loadingAudienceDatsWhy: false,
    audienceDatsWhy,
  });

export const getAudienceDatsWhyFailure = (
  state: Object,
  { errors, data }: Object
) =>
  state.merge({
    audienceDatsWhy: data,
    loadingAudienceDatsWhy: false,
    errors,
  });

export const getAudienceHoursOfDayRequest = (state: Object) =>
  state.merge({
    loadingAudienceHoursOfDay: true,
    errors: [],
  })

export const getAudienceHoursOfDaySuccess = (
  state: Object,
  { audienceHoursOfDay }: Object
) =>
  state.merge({
    loadingAudienceHoursOfDay: false,
    audienceHoursOfDay,
  });

export const getAudienceHoursOfDayFailure = (
  state: Object,
  { errors, data }: Object
) =>
  state.merge({
    audienceHoursOfDay: data,
    loadingAudienceHoursOfDay: false,
    errors,
  });

export const getAudienceDaysOfWeekRequest = (state: Object) =>
  state.merge({
    loadingAudienceDaysOfWeek: true,
    errors: [],
  })

export const getAudienceDaysOfWeekSuccess = (
  state: Object,
  { audienceDaysOfWeek }: Object
) =>
  state.merge({
    loadingAudienceDaysOfWeek: false,
    audienceDaysOfWeek,
  });

export const getAudienceDaysOfWeekFailure = (
  state: Object,
  { errors, data }: Object
) =>
  state.merge({
    audienceDaysOfWeek: data,
    loadingAudienceDaysOfWeek: false,
    errors,
  });

export const getProposalAudienceRequest = (state: Object) =>
  state.merge({
    loadingProposalAudience: true,
    errors: [],
  });

export const getProposalAudienceSuccess = (state: Object, { proposalAudience }: Object) =>
  state.merge({
    loadingProposalAudience: false,
    proposalAudience,
  });

export const getProposalAudienceFailure = (state: Object, { errors }: Object) =>
  state.merge({
    loadingProposalAudience: false,
    errors,
  });

export const setCanvaInit = (state: Object, { canva }: Object) =>
  state.merge({
    canva
  });

export const getCurrencyExchangeRateRequest = (state: Object) =>
  state.merge({
    gettingCurrencyExchangeRate: true,
  });

export const getCurrencyExchangeRateSuccess = (state: Object, { currencyExchangeRate }: Object) =>
  state.merge({
    gettingCurrencyExchangeRate: false,
    currencyExchangeRate,
  });

export const getCurrencyExchangeRateFailure = (state: Object, { errors }: Object) =>
  state.merge({
    gettingCurrencyExchangeRate: false,
    errors,
  });

export const getConversionRatesRequest = (state: Object) =>
  state.merge({
    gettingConversionRates: true,
  });

export const getConversionRatesSuccess = (state: Object, { conversionRates }: Object) =>
  state.merge({
    gettingConversionRates: false,
    conversionRates,
  });

export const getConversionRatesFailure = (state: Object, { errors }: Object) =>
  state.merge({
    gettingConversionRates: false,
    errors,
  });
export const getCategoriesRequest = (state: Object) =>
  state.merge({
    loadingCategories: true,
  });

export const getCategoriesSuccess = (state: Object, { categories }: Object) =>
  state.merge({
    categories: categories,
    loadingCategories: false,
  });

export const getCategoriesFailure = (state: Object, { errors }: Object) =>
  state.merge({
    loadingCategories: false,
    errors,
  });

export const setCategoriesId = (
  state: Object,
  { categoriesId }: Object
) =>
  state.merge({
    categoriesId,
  });

export const getContentsByCompanyRequest = (state: Object) =>
  state.merge({
    loadingContentsbyCompany: true,
    errors: [],
    libraryContents: [],
  });

export const getContentsByCompanySuccess = (state: Object, { libraryContents }) =>
  state.merge({
    loadingContentsbyCompany: false,
    libraryContents,
  });

export const setLibraryContents = (state: Object, { libraryContents }) =>
  state.merge({
    libraryContents,
  });

export const getContentsByCompanyFailure = (state: Object, { errors }) =>
  state.merge({
    loadingContentsbyCompany: false,
    errors,
  });

export const setListOfContentsInCampaign = (state: Object, { contents }: Object) => {
  return state.merge({
    listOfContentsInCampaign: [...contents],
  });
};

export const deleteContentFromCampaign = (state: Object, { contentId }: Object) => {
  const listResolutionWithContent = state.listResolutionWithContent.map(item => {
    return {
      ...item,
      contentsDisplays: item.contentsDisplays.filter((content) => content.contentId !== contentId),
      contents: item.contents.filter((content) => content.id !== contentId),
    };
  });
  const customContents = state.customContents.filter((content) => content.content.id !== contentId);
  return state.merge({
    listOfContentsInCampaign: state.listOfContentsInCampaign.filter((content) => content.id !== contentId),
    listResolutionWithContent: listResolutionWithContent,
    customContents: customContents,
  });
};


export const remplaceContentFromRelation = (state: Object, { contentId, displayId, contentToReplace }: Object) => {
  const listResolutionWithContent = state.listResolutionWithContent.map(item => {
    // totalRelation is necesary to know if the content is only one or more than one for delete of the list
    const totalRelation = item.contentsDisplays.find(relation => relation.id === contentId);
    if (totalRelation.find(relation => relation.displayId === displayId)) {
      return {
        ...item,
        contents: totalRelation > 1 ?
          (item.contents.map( content => content.id === contentId ? contentToReplace : content )):
          item.contents,
        contentsDisplays: item.contentsDisplays.map(contentDisplay =>
          contentDisplay.contentId === contentId ? { ...contentDisplay, contentId: contentToReplace.id } : contentDisplay
        )
      };
    }
    return item;
  });

  const customContents = state.customContents.map(customContent =>
    customContent.content.id === contentId
      ? { ...customContent, content: contentToReplace }
      : customContent
  );

  return state.merge({
    listOfContentsInCampaign: state.listOfContentsInCampaign.map(content =>
      content.id === contentId ? contentToReplace : content
    ),
    listResolutionWithContent: listResolutionWithContent,
    customContents: customContents,
  });
};

export const remplaceContentForUniqueVersion = (state: Object, { contentId, listDisplay, contentToReplace }: Object) => {
  const customContents = state.customContents.map(customContent =>
    customContent.content.id === contentId && (listDisplay.includes(customContent.displayId) || listDisplay.length === 0)
      ? { ...customContent, content: contentToReplace }
      : customContent
  );
  return state.merge({
    customContents: customContents,
  });
};


export const remplaceContentFromCampaign = (state: Object, { contentId, contentToReplace}: Object) => {

  const listResolutionWithContent = state.listResolutionWithContent.map(item => {
    if (item.contents.find(content => content.id === contentId) ) {
        return {
          ...item,
          contents: item.contents.map(content =>
            content.id === contentId ? contentToReplace : content
          ),
          contentsDisplays: item.contentsDisplays.map(contentDisplay =>
            contentDisplay.contentId === contentId ? { ...contentDisplay, contentId: contentToReplace.id } : contentDisplay
          )
        };
    }
    return item;
  });

  const customContents = !state.customContents ? [] : state.customContents.map(customContent =>
    customContent.content.id === contentId
      ? { ...customContent, content: contentToReplace }
      : customContent
  );

  let currentContentsDisplays = state.currentContentsDisplays
  if(currentContentsDisplays) {
    currentContentsDisplays = currentContentsDisplays.map(content =>
      content.contentId === contentId
        ? { ...content, contentId: contentToReplace.id }
        : content
    );
  }

  let listOfContentsInCampaign = state.listOfContentsInCampaign
  // if(change != "not_content_in_campaign") {
    listOfContentsInCampaign = state.listOfContentsInCampaign.map(content =>
      content.id === contentId ? contentToReplace : content
    );
  // }

  return state.merge({
    currentContentsDisplays: currentContentsDisplays,
    listOfContentsInCampaign: listOfContentsInCampaign,
    listResolutionWithContent: listResolutionWithContent,
    customContents: customContents
  });
};

// Delete all contents and relation whith this contents
export const cleanListOfContentsInCampaign = (state: Object) => {
  const updatedListResolutionWithContent = state.listResolutionWithContent.map(item => {
    return {
      ...item,
      contentsDisplays: [],
      contents: [],
    };
  });

  return state.merge({
    listResolutionWithContent: updatedListResolutionWithContent,
    listOfContentsInCampaign: [],
  })
};

export const updateListOfContentsInCampaign = (state: Object, { content }: Object) => {
  const updatedListOfContents = state.listOfContentsInCampaign.map(item => {
    if (item.id === content.id) return content;
    return item;
  });

  return state.merge({
    listOfContentsInCampaign: updatedListOfContents,
  });
};

export const setListResolutionWithContent = (state: Object, { listResolutionWithContent }) =>
  state.merge({
    listResolutionWithContent: listResolutionWithContent,
  });


export const setLibraryRules = (state: Object, { libraryRules }) =>
  state.merge({
    // loadingLibraryRules: false,
    libraryRules,
  });

export const cleanLibraryRules = (state: Object) =>
  state.merge({
    libraryRules: [],
  });

export const setManagerDisplayFilter = (state: Object, { managerDisplayFilter }) =>
  state.merge({
    managerDisplayFilter,
  });

export const setManagerDisplaySelector = (state: Object, { managerDisplaySelector }) =>
  state.merge({
    managerDisplaySelector,
  });

export const disableManagerDisplayFilter = (state: Object, { disabledManagerDisplayFilter }) =>
  state.merge({
    disabledManagerDisplayFilter: disabledManagerDisplayFilter,
  });
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_DATES]: setDates,
  [Types.SET_BOUNDS]: setBounds,
  [Types.SET_PRICE]: setPrice,
  [Types.SET_COORDS]: setCoords,
  [Types.SET_MAP_ZOOM]: setMapZoom,
  [Types.SET_SHOWS_PER_DAY_MIN]: setShowsPerDayMin,
  [Types.SET_LOCATION_TYPE]: setLocationType,
  [Types.SET_LOCATION_SUB_TYPE]: setLocationSubType,
  [Types.SET_SIZE_TYPE]: setSizeType,
  [Types.SET_POI_FILTERS]: setPoiFilters,
  [Types.RESET_POI_FILTERS]: resetPoiFilters,
  [Types.SET_PAGE]: setPage,
  [Types.SET_PAGE_CAMPAIGNS]: setPageCampaigns,
  [Types.RESET_FILTERS]: resetFilters,
  [Types.TOGGLE_FILL_SCREEN]: toggleFillScreen,
  [Types.UPDATE_FIELD_IN_CUSTOM_CONTENTS]: updateFieldInCustomContents,
  [Types.UPDATE_CUSTOM_CONTENTS]: updateCustomContents,
  [Types.UPDATE_FIELD_IN_CONTENT]: updateFieldInContent,
  [Types.UPDATE_RULES_RELATION_SCREEN]: updateRulesRelationScreen,
  [Types.CLONE_RULES_RELATION_SCREEN]: cloneRulesRelationScreen,
  [Types.CLONE_SPOTS_RELATION_SCREEN]: cloneSpotsRelationScreen,
  [Types.UPDATE_IMPRESSIONS_DISPLAY]: updateImpressionsDisplay,
  [Types.UPDATE_PRICE_CART_BY_DISCOUNT]: updatePriceCartByDiscount,
  [Types.ADD_DISPLAY_TO_CART]: addDisplayToCart,
  [Types.ADD_LIST_DISPLAY_TO_CART]: addListDisplayToCart,
  [Types.SET_DEFAULT_CONTENT]: setDefaultContent,
  [Types.CLEAR_CUSTOM_CONTENTS]: clearCustomContents,
  [Types.ADD_RELATION_DISPLAY]: addRelationDisplay,
  [Types.ADD_CUSTOM_CONTENT]: addCustomContent,
  [Types.ADD_CUSTOM_CONTENT_FROM_CAMPAIGN]: addCustomContentFromCampaign,
  [Types.REMOVE_RELATION_FROM_CUSTOM_CONTENTS]: removeRelationFromCustomContents,
  [Types.REMOVE_DISPLAY_FROM_CART]: removeDisplayFromCart,
  [Types.REMOVE_BUNDLES_FROM_CART]: removeBundlesFromCart,
  [Types.UPDATE_BUNDLES]: updateBundles,
  [Types.GET_DISPLAYS_CART_SUCCESS]: getDisplaysCartSuccess,
  [Types.GET_DISPLAYS_CART_REQUEST]: getDisplaysCartRequest,
  [Types.GET_DISPLAYS_CART_FAILURE]: getDisplaysCartFailure,
  [Types.REMOVE_RELATION_FROM_DISPLAY]: removeRelationFromDisplay,
  [Types.REMOVE_DISPLAY_ON_CAMPAIGN]: removeDisplayOnCampaign,
  [Types.REMOVE_RELATIONS_ON_CAMPAIGN]: removeRelationsOnCampaign,
  [Types.REMOVE_DISPLAYS_CART]: removeDisplaysCart,
  [Types.SET_CAMPAIGN_NAME]: setCampaignName,
  [Types.SET_TOLERANCE_RESOLUTION]: setToleranceResolution,
  [Types.SET_TOLERANCE_RESOLUTION_INIT]: setToleranceResolutionInit,
  [Types.SET_CAMPAIGN_DESCRIPTION]: setCampaignDescription,
  [Types.SET_IS_CAMPAIGN_TEST]: setIsCampaignTest,
  [Types.SET_IS_CAMPAIGN_PAUSE]: setIsCampaignPause,
  [Types.SET_PARAMS_FROM_URL]: setParamsFromUrl,
  [Types.UPDATE_DEFAULT_CONTENT]: updateDefaultContent,
  [Types.UPDATE_DEFAULT_CONTENT_REQUEST]: updateDefaultContentRequest,
  [Types.UPDATE_DEFAULT_CONTENT_SUCCESS]: updateDefaultContentSuccess,
  [Types.UPDATE_DEFAULT_CONTENT_FAILURE]: updateDefaultContentFailure,
  [Types.UPDATE_CUSTOM_CONTENT_REQUEST]: updateCustomContentRequest,
  [Types.UPDATE_CUSTOM_CONTENT_SUCCESS]: updateCustomContentSuccess,
  [Types.UPDATE_PROMISE_SHOWS_IN_RELATIONS]: updatePromiseShowsInRelations,
  [Types.UPDATE_CUSTOM_CONTENT_FAILURE]: updateCustomContentFailure,
  [Types.UPDATE_CONTENT_CAMPAIGN_REQUEST]: updateContentCampaignRequest,
  [Types.UPDATE_RELATION_CAMPAIGN_REQUEST]:updateRelationCampaignRequest,
  [Types.UPDATE_CONTENT_CAMPAIGN_SUCCESS]: updateContentCampaignSuccess,
  [Types.UPDATE_CONTENT_DISPLAY_BY_CONTENT]: updateContentDisplayByContent,
  [Types.CREATE_NEW_CONTENT_SUCCESS]: createNewContentSuccess,
  [Types.LOADING_CONTENT_IN_CAMPAIGN]: loadingContentInCampaign,
  [Types.UPDATE_CONTENT_CAMPAIGN_FAILURE]: updateContentCampaignFailure,
  [Types.CLEAN_UPDATE_SPOTS]: cleanUpdateSpots,
  [Types.CLEAR_CAMPAIGN_DATA]: clearCampaignData,
  [Types.GET_DISPLAYS_REQUEST]: getDisplaysRequest,
  [Types.GET_DISPLAYS_SUCCESS]: getDisplaysSuccess,
  [Types.GET_DISPLAYS_FAILURE]: getDisplaysFailure,
  [Types.GET_DISPLAY_INFO_REQUEST]: getDisplayInfoRequest,
  [Types.GET_DISPLAY_INFO_SUCCESS]: getDisplayInfoSuccess,
  [Types.GET_DISPLAY_INFO_FAILURE]: getDisplayInfoFailure,
  [Types.GET_DISPLAYS_INFO_REQUEST]: getDisplaysInfoRequest,
  [Types.GET_DISPLAYS_INFO_SUCCESS]: getDisplaysInfoSuccess,
  [Types.GET_DISPLAYS_INFO_FAILURE]: getDisplaysInfoFailure,
  [Types.GET_EXCEL_DISPLAYS_REQUEST]: getExcelDisplaysRequest,
  [Types.GET_EXCEL_DISPLAYS_SUCCESS]: getExcelDisplaysSuccess,
  [Types.GET_EXCEL_DISPLAYS_FAILURE]: getExcelDisplaysFailure,
  [Types.UPLOAD_FILE_REQUEST]: uploadFileRequest,
  [Types.UPLOAD_FILE_SUCCESS]: uploadFileSuccess,
  [Types.UPLOAD_FILE_FAILURE]: uploadFileFailure,
  [Types.CREATE_CAMPAIGN_REQUEST]: createCampaignRequest,
  [Types.CREATE_CAMPAIGN_SUCCESS]: createCampaignSuccess,
  [Types.CREATE_CAMPAIGN_FAILURE]: createCampaignFailure,
  [Types.GET_CAMPAIGNS_REQUEST]: getCampaignsRequest,
  [Types.GET_CAMPAIGNS_SUCCESS]: getCampaignsSuccess,
  [Types.GET_CAMPAIGNS_FAILURE]: getCampaignsFailure,
  [Types.GET_CAMPAIGN_REQUEST]: getCampaignRequest,
  [Types.GET_CAMPAIGN_SUCCESS]: getCampaignSuccess,
  [Types.GET_CAMPAIGN_FAILURE]: getCampaignFailure,
  [Types.DELETE_CAMPAIGN_REQUEST]: deleteCampaignRequest,
  [Types.DELETE_CAMPAIGN_SUCCESS]: deleteCampaignSuccess,
  [Types.DELETE_CAMPAIGN_FAILURE]: deleteCampaignFailure,
  [Types.CREATE_REPLICATE_CAMPAIGN_REQUEST]: createReplicateCampaignRequest,
  [Types.CREATE_REPLICATE_CAMPAIGN_SUCCESS]: createReplicateCampaignSuccess,
  [Types.CREATE_REPLICATE_CAMPAIGN_FAILURE]: createReplicateCampaignFailure,
  [Types.REQUEST_SPECIAL_CPM_REQUEST]: requestSpecialCpmRequest,
  [Types.REQUEST_SPECIAL_CPM_SUCCESS]: requestSpecialCpmSuccess,
  [Types.REQUEST_SPECIAL_CPM_FAILURE]: requestSpecialCpmFailure,
  [Types.REQUEST_SPECIAL_PRICE_REQUEST]: requestSpecialPriceRequest,
  [Types.REQUEST_SPECIAL_PRICE_SUCCESS]: requestSpecialPriceSuccess,
  [Types.REQUEST_SPECIAL_PRICE_FAILURE]: requestSpecialPriceFailure,
  [Types.CHANGE_STATE_UPDATE_CONTENT]: changeStateUpdateContent,
  [Types.ACTIVATE_EDIT_CAMPAIGN]: activateEditCampaign,
  [Types.EDIT_CAMPAIGN_INACTIVE]: editCampaignInactive,
  [Types.EDIT_CAMPAIGN_REQUEST]: editCampaignRequest,
  [Types.EDIT_CAMPAIGN_SUCCESS]: editCampaignSuccess,
  [Types.EDIT_CAMPAIGN_FAILURE]: editCampaignFailure,
  [Types.EDIT_CAMPAIGN_TOTAL_PROMISED_SHOWS]: editCampaignTotalPromisedShows,

  [Types.ARCHIVE_CAMPAIGN_REQUEST]: archiveCampaignRequest,
  [Types.ARCHIVE_CAMPAIGN_SUCCESS]: archiveCampaignSuccess,
  [Types.ARCHIVE_CAMPAIGN_FAILURE]: archiveCampaignRequest,

  [Types.GET_CLIENTS_REQUEST]: getClientsRequest,
  [Types.GET_CLIENTS_SUCCESS]: getClientsSuccess,
  [Types.GET_CLIENTS_FAILURE]: getClientsFailure,
  [Types.SET_CLIENT]: setClient,
  [Types.SET_CIRCUIT]: setCircuit,
  [Types.CLEAR_CLIENTS]: clearClients,

  [Types.GET_EXTERNAL_DSPS_REQUEST]: getExternalDspsRequest,
  [Types.GET_EXTERNAL_DSPS_SUCCESS]: getExternalDspsSuccess,
  [Types.GET_EXTERNAL_DSPS_FAILURE]: getExternalDspsFailure,
  [Types.SET_DSP]: setDsp,
  [Types.CLEAR_DSP]: clearDsp,

  [Types.SET_PROGRAMMATIC]: setProgrammatic,
  [Types.SET_BUDGET]: setBudget,
  [Types.UPDATE_REAL_AMOUNT]: updateRealAmount,
  [Types.SET_AUTOMOVIL_FILTER]: setAutomovilFilter,
  [Types.SET_SHOPPING_FILTER]: setShoppingFilter,
  [Types.SET_SERVICES_FILTER]: setServicesFilter,
  [Types.SET_TRAINING_FILTER]: setTrainingFilter,
  [Types.SET_EDUCATION_FILTER]: setEducationFilter,
  [Types.SET_BANK_FILTER]: setBankFilter,
  [Types.SET_TRANSPORT_FILTER]: setTransportFilter,
  [Types.SET_GOVERNMENT_FILTER]: setGovernmentFilter,
  [Types.SET_HOTEL_FILTER]: setHotelFilter,
  [Types.SET_FOOD_FILTER]: setFoodFilter,
  [Types.SET_HEALTHY_FILTER]: setHealthyFilter,
  [Types.SET_GENDER_WOMAN_FILTER]: setGenderWomanFilter,
  [Types.SET_GENDER_MAN_FILTER]: setGenderManFilter,
  [Types.SET_DEFAULT_RULES]: setDefaultRules,

  [Types.GET_BRANDS_REQUEST]: getBrandsRequest,
  [Types.GET_BRANDS_SUCCESS]: getBrandsSuccess,
  [Types.GET_BRANDS_FAILURE]: getBrandsFailure,
  [Types.SET_BRANDS]: setBrands,
  [Types.SET_BRAND]: setBrand,
  [Types.CLEAR_BRANDS]: clearBrands,

  [Types.SET_COUNTRIES]: setCountries,

  [Types.GET_QUOTES_REQUEST]: getQuotesRequest,
  [Types.SET_ARCHIVED_QUOTE_REQUEST]: setArchivedQuoteRequest,
  [Types.SET_ARCHIVED_QUOTE_SUCCESS]: setArchivedQuoteSuccess,
  [Types.SET_ARCHIVED_QUOTE_FAILURE]: setArchivedQuoteFailure,

  [Types.GET_QUOTES_SUCCESS]: getQuotesSuccess,
  [Types.GET_QUOTES_FAILURE]: getQuotesFailure,
  [Types.GET_QUOTE_REQUEST]: getQuoteRequest,
  [Types.GET_QUOTE_SUCCESS]: getQuoteSuccess,
  [Types.GET_QUOTE_FAILURE]: getQuoteFailure,
  [Types.CREATE_QUOTE_REQUEST]: createQuoteRequest,
  [Types.CREATE_QUOTE_SUCCESS]: createQuoteSuccess,
  [Types.CREATE_QUOTE_FAILURE]: createQuoteFailure,
  [Types.EDIT_QUOTE_REQUEST]: editQuoteRequest,
  [Types.EDIT_QUOTE_SUCCESS]: editQuoteSuccess,
  [Types.EDIT_QUOTE_FAILURE]: editQuoteFailure,
  [Types.DELETE_QUOTE_REQUEST]: deleteQuoteRequest,
  [Types.DELETE_QUOTE_SUCCESS]: deleteQuoteSuccess,
  [Types.DELETE_QUOTE_FAILURE]: deleteQuoteFailure,

  [Types.DISPLAY_RESET]: displayReset,
  // [Types.GET_MERCADO_PAGO_PAYMENT_METHODS_REQUEST]: getMercadoPagoPaymentMethodsRequest,
  [Types.UPDATE_CUSTOM_CONTENTS]: updateCustomContents,
  [Types.GET_MERCADO_PAGO_PAYMENT_METHODS]: getMercadoPagoPaymentMethods,
  // [Types.GET_MERCADO_PAGO_PAYMENT_METHODS_FAILURE]: getMercadoPagoPaymentMethodsFailure,
  [Types.CREATE_PAYMENT_REQUEST]: createPaymentRequest,
  [Types.CREATE_PAYMENT_SUCCESS]: createPaymentSuccess,
  [Types.CREATE_PAYMENT_FAILURE]: createPaymentFailure,

  [Types.CREATE_PURCHASE_ORDERS_REQUEST]: createPurchaseOrdersRequest,
  [Types.CREATE_PURCHASE_ORDERS_SUCCESS]: createPurchaseOrdersSuccess,
  [Types.CREATE_PURCHASE_ORDERS_FAILURE]: createPurchaseOrdersFailure,

  [Types.GET_PAYMENT_REQUEST]: getPaymentRequest,
  [Types.GET_PAYMENT_SUCCESS]: getPaymentSuccess,
  [Types.GET_PAYMENT_FAILURE]: getPaymentFailure,

  [Types.DELETE_PAYMENT_REQUEST]: deletePaymentRequest,
  [Types.DELETE_PAYMENT_SUCCESS]: deletePaymentSuccess,
  [Types.DELETE_PAYMENT_FAILURE]: deletePaymentFailure,

  [Types.GET_CAMPAIGN_PAYMENTS_REQUEST]: getCampaignPaymentsRequest,
  [Types.GET_CAMPAIGN_PAYMENTS_SUCCESS]: getCampaignPaymentsSuccess,
  [Types.GET_CAMPAIGN_PAYMENTS_FAILURE]: getCampaignPaymentsFailure,

  [Types.CREATE_COUPON_REQUEST]: createCouponRequest,
  [Types.CREATE_COUPON_SUCCESS]: createCouponSuccess,
  [Types.CREATE_COUPON_FAILURE]: createCouponFailure,

  [Types.DELETE_COUPON_REQUEST]: deleteCouponRequest,
  [Types.DELETE_COUPON_SUCCESS]: deleteCouponSuccess,
  [Types.DELETE_COUPON_FAILURE]: deleteCouponFailure,

  [Types.GET_MP_INIT_POINT_REQUEST]: getMpInitPointRequest,
  [Types.GET_MP_INIT_POINT_SUCCESS]: getMpInitPointSuccess,
  [Types.GET_MP_INIT_POINT_FAILURE]: getMpInitPointFailure,

  [Types.GET_REPORT_REQUEST]: getReportRequest,
  [Types.GET_REPORT_SUCCESS]: getReportSuccess,
  [Types.GET_REPORT_FAILURE]: getReportFailure,

  [Types.GET_PROPOSAL_REQUEST]: getProposalRequest,
  [Types.GET_PROPOSAL_SUCCESS]: getProposalSuccess,
  [Types.GET_PROPOSAL_FAILURE]: getProposalFailure,

  [Types.GET_SINGLE_REPORT_REQUEST]: getSingleReportRequest,
  [Types.GET_SINGLE_REPORT_SUCCESS]: getSingleReportSuccess,
  [Types.GET_SINGLE_REPORT_FAILURE]: getSingleReportFailure,
  [Types.EDIT_SINGLE_REPORT]: editSingleReport,

  [Types.GET_LAST_BILLING_INFO_REQUEST]: getLastBillingInfoRequest,
  [Types.GET_LAST_BILLING_INFO_SUCCESS]: getLastBillingInfoSuccess,
  [Types.GET_LAST_BILLING_INFO_FAILURE]: getLastBillingInfoFailure,

  [Types.SET_CANVA_INIT]: setCanvaInit,

  // [Types.SET_CURRENCY]: setCurrency,

  [Types.GET_AUDIENCE_REQUEST]: getAudienceRequest,
  [Types.GET_AUDIENCE_DATS_WHY_REQUEST]: getAudienceDatsWhyRequest,
  [Types.SET_AUDIENCE_DATS_WHY]: setAudienceDatsWhy,
  [Types.GET_AUDIENCE_DATS_WHY_SUCCESS]: getAudienceDatsWhySuccess,
  [Types.GET_AUDIENCE_DATS_WHY_FAILURE]: getAudienceDatsWhyFailure,

  [Types.GET_AUDIENCE_HOURS_OF_DAY_REQUEST]: getAudienceHoursOfDayRequest,
  [Types.GET_AUDIENCE_HOURS_OF_DAY_SUCCESS]: getAudienceHoursOfDaySuccess,
  [Types.GET_AUDIENCE_HOURS_OF_DAY_FAILURE]: getAudienceHoursOfDayFailure,

  [Types.GET_AUDIENCE_DAYS_OF_WEEK_REQUEST]: getAudienceDaysOfWeekRequest,
  [Types.GET_AUDIENCE_DAYS_OF_WEEK_SUCCESS]: getAudienceDaysOfWeekSuccess,
  [Types.GET_AUDIENCE_DAYS_OF_WEEK_FAILURE]: getAudienceDaysOfWeekFailure,

  [Types.GET_PROPOSAL_AUDIENCE_REQUEST]: getProposalAudienceRequest,
  [Types.GET_PROPOSAL_AUDIENCE_SUCCESS]: getProposalAudienceSuccess,
  [Types.GET_PROPOSAL_AUDIENCE_FAILURE]: getProposalAudienceFailure,

  [Types.GET_AUDIENCE_SUCCESS]: getAudienceSuccess,
  [Types.GET_AUDIENCE_FAILURE]: getAudienceFailure,
  [Types.CLEAN_AUDIENCE]: cleanAudience,

  [Types.GET_CURRENCY_EXCHANGE_RATE_REQUEST]: getCurrencyExchangeRateRequest,
  [Types.GET_CURRENCY_EXCHANGE_RATE_SUCCESS]: getCurrencyExchangeRateSuccess,
  [Types.GET_CURRENCY_EXCHANGE_RATE_FAILURE]: getCurrencyExchangeRateFailure,

  [Types.GET_CONVERSION_RATES_REQUEST]: getConversionRatesRequest,
  [Types.GET_CONVERSION_RATES_SUCCESS]: getConversionRatesSuccess,
  [Types.GET_CONVERSION_RATES_FAILURE]: getConversionRatesFailure,

  [Types.GET_CATEGORIES_REQUEST]: getCategoriesRequest,
  [Types.GET_CATEGORIES_SUCCESS]: getCategoriesSuccess,
  [Types.GET_CATEGORIES_FAILURE]: getCategoriesFailure,
  [Types.SET_CATEGORIES_ID]: setCategoriesId,
  [Types.GET_CONTENTS_BY_COMPANY_REQUEST]: getContentsByCompanyRequest,
  [Types.SET_LIBRARY_CONTENTS]: setLibraryContents,
  [Types.GET_CONTENTS_BY_COMPANY_SUCCESS]: getContentsByCompanySuccess,
  [Types.GET_CONTENTS_BY_COMPANY_FAILURE]: getContentsByCompanyFailure,

  [Types.SET_LIST_OF_CONTENTS_IN_CAMPAIGN]: setListOfContentsInCampaign,
  [Types.CLEAN_LIST_OF_CONTENTS_IN_CAMPAIGN]: cleanListOfContentsInCampaign,
  [Types.DELETE_CONTENT_FROM_CAMPAIGN]: deleteContentFromCampaign,
  [Types.UPDATE_LIST_OF_CONTENTS_IN_CAMPAIGN]: updateListOfContentsInCampaign,

  [Types.SET_LIST_RESOLUTION_WITH_CONTENT]: setListResolutionWithContent,
  [Types.SET_LIBRARY_RULES]: setLibraryRules,
  [Types.SET_MANAGER_DISPLAY_FILTER]: setManagerDisplayFilter,
  [Types.SET_MANAGER_DISPLAY_SELECTOR]: setManagerDisplaySelector,
  [Types.DISABLE_MANAGER_DISPLAY_FILTER]: disableManagerDisplayFilter,
  [Types.CLEAN_LIBRARY_RULES]: cleanLibraryRules,
  [Types.SET_NEW_VERSION_CAMPAIGN]: setNewVersionCampaign,
  [Types.REMPLACE_CONTENT_FROM_CAMPAIGN]: remplaceContentFromCampaign,
  [Types.REMPLACE_CONTENT_FOR_UNIQUE_VERSION]: remplaceContentForUniqueVersion,
  [Types.REMPLACE_CONTENT_FROM_RELATION]: remplaceContentFromRelation,

});
